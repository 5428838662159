import MHWPic1 from "../assets/fighter/men-heavyweight/pic1.png";
import MHWPic2 from "../assets/fighter/men-heavyweight/pic2.png";
import MHWPic3 from "../assets/fighter/men-heavyweight/pic3.png";
import MHWPic4 from "../assets/fighter/men-heavyweight/pic4.png";
import MHWPic5 from "../assets/fighter/men-heavyweight/pic5.png";
import MHWPic6 from "../assets/fighter/men-heavyweight/pic6.png";
import MHWPic7 from "../assets/fighter/men-heavyweight/pic7.png";
import MHWPic8 from "../assets/fighter/men-heavyweight/pic8.png";
import MHWPic9 from "../assets/fighter/men-heavyweight/pic9.png";
import MHWPic10 from "../assets/fighter/men-heavyweight/pic10.png";
import MHWPic11 from "../assets/fighter/men-heavyweight/pic11.png";
import MHWPic12 from "../assets/fighter/men-heavyweight/pic12.png";
import MHWPic13 from "../assets/fighter/men-heavyweight/pic13.png";
import MHWPic14 from "../assets/fighter/men-heavyweight/pic14.png";
import MHWPic15 from "../assets/fighter/men-heavyweight/todd_duffee.png";
import MHWPic16 from "../assets/fighter/men-heavyweight/fernando-rodrigues-jr.png";
import MHWPic17 from "../assets/fighter/men-heavyweight/mostafa-salehizadeh.png";
import MHWPic18 from "../assets/fighter/men-heavyweight/christian-larsen.png";
import MHWPic19 from "../assets/fighter/men-heavyweight/davion-franklin.png";
import MHWPic20 from "../assets/fighter/men-heavyweight/guto-inocente.png";
import MHWPic21 from "../assets/fighter/men-heavyweight/ilir-latifi.png";
import MHWPic22 from "../assets/fighter/men-heavyweight/juan-adams.png";
import MHWPic23 from "../assets/fighter/men-heavyweight/matheus-scheffel.png";
import MHWPic24 from "../assets/fighter/men-heavyweight/oscar-cota.png";
import MHWPic25 from "../assets/fighter/men-heavyweight/quentin-domingos.png";
import MHWPic26 from "../assets/fighter/men-heavyweight/rakim-cleveland.png";
import MHWPic27 from "../assets/fighter/men-heavyweight/said-sowma.png";
import MHWPic28 from "../assets/fighter/men-heavyweight/thiago-santos.png";
import MHWPic29 from "../assets/fighter/men-heavyweight/ahmed-jabbar-al-rubaye.png";
import MHWPic30 from "../assets/fighter/men-heavyweight/jordan-heiderman.png";
import MHWPic31 from "../assets/fighter/men-heavyweight/roman-lukashevich.png";
import MHWPic32 from "../assets/fighter/men-heavyweight/ronald-girones-sago.png";
import MHWPic33 from "../assets/fighter/men-heavyweight/alexander-soldatkin.png";
import MHWPic34 from "../assets/fighter/men-heavyweight/ali-isaev.png";
import MHWPic35 from "../assets/fighter/men-heavyweight/bruno-cappelozza.png";

import MLHWPic1 from "../assets/fighter/men-light-heavyweight/pic1.jpg";
import MLHWPic2 from "../assets/fighter/men-light-heavyweight/pic2.jpg";
import MLHWPic3 from "../assets/fighter/men-light-heavyweight/pic3.jpg";
import MLHWPic4 from "../assets/fighter/men-light-heavyweight/pic4.jpg";
import MLHWPic5 from "../assets/fighter/men-light-heavyweight/pic5.jpg";
import MLHWPic6 from "../assets/fighter/men-light-heavyweight/pic6.jpg";
import MLHWPic7 from "../assets/fighter/men-light-heavyweight/pic7.jpg";
import MLHWPic8 from "../assets/fighter/men-light-heavyweight/pic8.jpg";
import MLHWPic9 from "../assets/fighter/men-light-heavyweight/mauricio_rua.png";
import MLHWPic10 from "../assets/fighter/men-light-heavyweight/omari_akhmedov.png";
import MLHWPic11 from "../assets/fighter/men-light-heavyweight/yoel_romero.png";
import MLHWPic12 from "../assets/fighter/men-light-heavyweight/ilir-latifi.png";
import MLHWPic13 from "../assets/fighter/men-light-heavyweight/thiago-santos.png";
import MLHWPic14 from "../assets/fighter/men-light-heavyweight/acacio-dos-santos.png";
import MLHWPic15 from "../assets/fighter/men-light-heavyweight/anton-turkalj.png";
import MLHWPic16 from "../assets/fighter/men-light-heavyweight/caio-machado.png";
import MLHWPic17 from "../assets/fighter/men-light-heavyweight/chris-camozzi.png";
import MLHWPic18 from "../assets/fighter/men-light-heavyweight/hasan-mezhiev.png";
import MLHWPic19 from "../assets/fighter/men-light-heavyweight/masoud-safari.png";
import MLHWPic20 from "../assets/fighter/men-light-heavyweight/omari-akhmedov.png";
import MLHWPic21 from "../assets/fighter/men-light-heavyweight/ovince-saint-preux.png";
// import MLHWPic22 from "../assets/fighter/men-light-heavyweight/przemyslaw-mysiala.png";
import MLHWPic23 from "../assets/fighter/men-light-heavyweight/ronny-markes.png";
import MLHWPic24 from "../assets/fighter/men-light-heavyweight/yoel-romero.png";
// import MLHWPic25 from "../assets/fighter/men-light-heavyweight/edinaldo-novaes.png";
import MLHWPic26 from "../assets/fighter/men-light-heavyweight/jamelle-jones.png";
import MLHWPic27 from "../assets/fighter/men-light-heavyweight/julius-anglickas.png";
// import MLHWPic28 from "../assets/fighter/men-light-heavyweight/luis-henrique.png";
import MLHWPic29 from "../assets/fighter/men-light-heavyweight/mateusz-duczmal.png";
import MLHWPic30 from "../assets/fighter/men-light-heavyweight/roman-lukashevich.png";
import MLHWPic31 from "../assets/fighter/men-light-heavyweight/devin-clark.png";
import MLHWPic32 from "../assets/fighter/men-light-heavyweight/luis-henrique-da-silva.png";
import MLHWPic33 from "../assets/fighter/men-light-heavyweight/norman-parke.png";
import MLHWPic34 from "../assets/fighter/men-light-heavyweight/antonio-carlos-junior.png";

import MMWPic1 from "../assets/fighter/men-middleweight/pic1.jpg";
import MMWPic2 from "../assets/fighter/men-middleweight/pic2.jpg";
import MMWPic3 from "../assets/fighter/men-middleweight/pic3.jpg";
import MMWPic4 from "../assets/fighter/men-middleweight/pic4.jpg";
import MMWPic5 from "../assets/fighter/men-middleweight/pic5.jpg";
// import MMWPic6 from "../assets/fighter/men-middleweight/pic6.jpg";
import MMWPic7 from "../assets/fighter/men-middleweight/pic7.jpg";
import MMWPic8 from "../assets/fighter/men-middleweight/pic8.jpg";
import MMWPic9 from "../assets/fighter/men-middleweight/pic9.jpg";
import MMWPic10 from "../assets/fighter/men-middleweight/pic10.jpg";
// import MMWPic11 from "../assets/fighter/men-middleweight/pic11.jpg";
import MMWPic12 from "../assets/fighter/men-middleweight/pic12.jpg";
// import MMWPic13 from "../assets/fighter/men-middleweight/andreas_michailidis.png";
import MMWPic14 from "../assets/fighter/men-middleweight/david_branch.png";
import MMWPic15 from "../assets/fighter/men-middleweight/douglas_lima.png";
// import MMWPic16 from "../assets/fighter/men-middleweight/jozef-wittner.jpg";
// import MMWPic17 from "../assets/fighter/men-middleweight/chris-weidman.png";
import MMWPic18 from "../assets/fighter/men-middleweight/uriah-hall.png";
import MMWPic19 from "../assets/fighter/men-middleweight/david-branch.png";
import MMWPic20 from "../assets/fighter/men-middleweight/dwight-grant.png";
import MMWPic21 from "../assets/fighter/men-middleweight/jack-marshman.png";
import MMWPic22 from "../assets/fighter/men-middleweight/john-allan.png";
import MMWPic23 from "../assets/fighter/men-middleweight/jorge-bueno.png";
import MMWPic24 from "../assets/fighter/men-middleweight/leandro-silva.png";
import MMWPic25 from "../assets/fighter/men-middleweight/lucas-alsina.png";
import MMWPic26 from "../assets/fighter/men-middleweight/mark-stoddard.png";
import MMWPic27 from "../assets/fighter/men-middleweight/nayib-lopez.png";
import MMWPic28 from "../assets/fighter/men-middleweight/simon-marcus.png";
import MMWPic29 from "../assets/fighter/men-middleweight/thomas-lane.png";
import MMWPic30 from "../assets/fighter/men-middleweight/yoel-romero.png";
import MMWPic31 from "../assets/fighter/men-middleweight/adis-dadovic.png";
import MMWPic32 from "../assets/fighter/men-middleweight/jonathan-figueroa.png";
import MMWPic33 from "../assets/fighter/men-middleweight/oton-jasse.png";
import MMWPic34 from "../assets/fighter/men-middleweight/pieter-buist.png";
import MMWPic35 from "../assets/fighter/men-middleweight/rodrigo-cavalheiro.png";
import MMWPic36 from "../assets/fighter/men-middleweight/tornike-gigauri.png";
import MMWPic37 from "../assets/fighter/men-middleweight/willker-lemos.png";
import MMWPic38 from "../assets/fighter/men-middleweight/zaza-dokhnadze.png";
import MMWPic39 from "../assets/fighter/men-middleweight/alan-silva.png";

import MWWPic1 from "../assets/fighter/men-welterweight/pic1.jpg";
import MWWPic2 from "../assets/fighter/men-welterweight/pic2.jpg";
// import MWWPic3 from "../assets/fighter/men-welterweight/pic3.jpg";
import MWWPic4 from "../assets/fighter/men-welterweight/pic4.jpg";
import MWWPic5 from "../assets/fighter/men-welterweight/pic5.jpg";
import MWWPic6 from "../assets/fighter/men-welterweight/pic6.jpg";
import MWWPic7 from "../assets/fighter/men-welterweight/pic7.jpg";
import MWWPic8 from "../assets/fighter/men-welterweight/pic8.jpg";
import MWWPic9 from "../assets/fighter/men-welterweight/pic9.jpg";
import MWWPic10 from "../assets/fighter/men-welterweight/pic10.jpg";
import MWWPic11 from "../assets/fighter/men-welterweight/pic11.jpg";
import MWWPic12 from "../assets/fighter/men-welterweight/pic12.jpg";
import MWWPic13 from "../assets/fighter/men-welterweight/pic13.jpg";
import MWWPic14 from "../assets/fighter/men-welterweight/pic14.jpg";
import MWWPic15 from "../assets/fighter/men-welterweight/pic15.jpg";
import MWWPic16 from "../assets/fighter/men-welterweight/tony_ferguson.png";
import MWWPic17 from "../assets/fighter/men-welterweight/efrain-escudero.png";
import MWWPic18 from "../assets/fighter/men-welterweight/gleison-tibau.png";
import MWWPic19 from "../assets/fighter/men-welterweight/sasha-palatnikov.png";
import MWWPic20 from "../assets/fighter/men-welterweight/yosdenis-cedeno.png";
import MWWPic21 from "../assets/fighter/men-welterweight/thiago-lima.png";
import MWWPic22 from "../assets/fighter/men-welterweight/pat-pytlik.png";
import MWWPic23 from "../assets/fighter/men-welterweight/marcos-lloreda.png";
import MWWPic24 from "../assets/fighter/men-welterweight/manuel-mena.png";
import MWWPic25 from "../assets/fighter/men-welterweight/lucas-clay.png";
// import MWWPic26 from "../assets/fighter/men-welterweight/lopez-mota.png";
import MWWPic27 from "../assets/fighter/men-welterweight/jose-lucas-alves.png";
import MWWPic28 from "../assets/fighter/men-welterweight/handesson-ferreira.png";
import MWWPic29 from "../assets/fighter/men-welterweight/gabriel-souza-galindo.png";
import MWWPic30 from "../assets/fighter/men-welterweight/eric-alequin.png";
import MWWPic31 from "../assets/fighter/men-welterweight/ej-brooks.png";
import MWWPic32 from "../assets/fighter/men-welterweight/carlos-petruzzella.png";
import MWWPic33 from "../assets/fighter/men-welterweight/ashley-reece.png";
import MWWPic34 from "../assets/fighter/men-welterweight/aziz-pakhrudinov.png";
import MWWPic35 from "../assets/fighter/men-welterweight/chris-brown.png";
import MWWPic36 from "../assets/fighter/men-welterweight/eduardo-souza.png";
import MWWPic37 from "../assets/fighter/men-welterweight/emin-sharifow.png";
import MWWPic38 from "../assets/fighter/men-welterweight/fabricio-azevedo.png";
// import MWWPic39 from "../assets/fighter/men-welterweight/ibrahima-mane.png";
// import MWWPic40 from "../assets/fighter/men-welterweight/jake-ellenberger.png";
import MWWPic41 from "../assets/fighter/men-welterweight/louis-glismann.png";
import MWWPic42 from "../assets/fighter/men-welterweight/magomed-kabardiev.png";
import MWWPic43 from "../assets/fighter/men-welterweight/ndidi-alonu.png";
// import MWWPic44 from "../assets/fighter/men-welterweight/stephen-costello.png";
import MWWPic45 from "../assets/fighter/men-welterweight/tahir-abdullaev.png";
import MWWPic46 from "../assets/fighter/men-welterweight/danny-roberts.png";
// import MWWPic47 from "../assets/fighter/men-welterweight/dillon-danis.png";
import MWWPic48 from "../assets/fighter/men-welterweight/neiman-gracie.png";
import MWWPic49 from "../assets/fighter/men-welterweight/dilano-taylor.png";

import MLWPic1 from "../assets/fighter/men-lightweight/pic1.jpg";
import MLWPic2 from "../assets/fighter/men-lightweight/pic2.jpg";
import MLWPic3 from "../assets/fighter/men-lightweight/pic3.jpg";
import MLWPic4 from "../assets/fighter/men-lightweight/pic4.jpg";
import MLWPic5 from "../assets/fighter/men-lightweight/pic5.jpg";
import MLWPic6 from "../assets/fighter/men-lightweight/pic6.jpg";
import MLWPic7 from "../assets/fighter/men-lightweight/pic7.jpg";
import MLWPic8 from "../assets/fighter/men-lightweight/pic8.jpg";
import MLWPic9 from "../assets/fighter/men-lightweight/pic9.jpg";
import MLWPic10 from "../assets/fighter/men-lightweight/pic10.jpg";
import MLWPic11 from "../assets/fighter/men-lightweight/pic11.jpg";
import MLWPic12 from "../assets/fighter/men-lightweight/pic12.jpg";
import MLWPic13 from "../assets/fighter/men-lightweight/pic13.jpg";
import MLWPic14 from "../assets/fighter/men-lightweight/pic14.jpg";
import MLWPic15 from "../assets/fighter/men-lightweight/pic15.jpg";
import MLWPic16 from "../assets/fighter/men-lightweight/pic16.jpg";
import MLWPic17 from "../assets/fighter/men-lightweight/pic17.jpg";
import MLWPic18 from "../assets/fighter/men-lightweight/pic18.jpg";
import MLWPic19 from "../assets/fighter/men-lightweight/willian-lima.png";
import MLWPic20 from "../assets/fighter/men-lightweight/pic20.jpg";
import MLWPic21 from "../assets/fighter/men-lightweight/pic21.jpg";
import MLWPic22 from "../assets/fighter/men-lightweight/pic22.jpg";
import MLWPic23 from "../assets/fighter/men-lightweight/pic23.jpg";
import MLWPic24 from "../assets/fighter/men-lightweight/feruz-usmonov.png";
import MLWPic25 from "../assets/fighter/men-lightweight/mukhiddin_mamazhonov.png";
import MLWPic26 from "../assets/fighter/men-lightweight/agshin-babaev.png";
import MLWPic27 from "../assets/fighter/men-lightweight/milos-janicic.png";
import MLWPic28 from "../assets/fighter/men-lightweight/radrigo-vargas.png";
import MLWPic29 from "../assets/fighter/men-lightweight/adam-masaev.png";
// import MLWPic30 from "../assets/fighter/men-lightweight/aleksandr-vertko.png";
import MLWPic31 from "../assets/fighter/men-lightweight/andrew-guisa.png";
import MLWPic32 from "../assets/fighter/men-lightweight/gustavo-ribeiro.png";
import MLWPic33 from "../assets/fighter/men-lightweight/hongang-yao.png";
import MLWPic34 from "../assets/fighter/men-lightweight/ignacio-capella.png";
import MLWPic35 from "../assets/fighter/men-lightweight/jairo-pacheco.png";
import MLWPic36 from "../assets/fighter/men-lightweight/juliano-prescendo.png";
import MLWPic37 from "../assets/fighter/men-lightweight/kyle-prepolec.png";
import MLWPic38 from "../assets/fighter/men-lightweight/magomed-akaev.png";
import MLWPic39 from "../assets/fighter/men-lightweight/magomed-yunusilau.png";
import MLWPic40 from "../assets/fighter/men-lightweight/nathan-ghareeb.png";
import MLWPic41 from "../assets/fighter/men-lightweight/nicolas-barna.png";
import MLWPic42 from "../assets/fighter/men-lightweight/saad-awad.png";
import MLWPic43 from "../assets/fighter/men-lightweight/thibault-gouti.png";
import MLWPic44 from "../assets/fighter/men-lightweight/tim-wilde.png";
import MLWPic45 from "../assets/fighter/men-lightweight/tom-oconnor.png";
import MLWPic46 from "../assets/fighter/men-lightweight/damir-ismagulov.png";
// import MLWPic47 from "../assets/fighter/men-lightweight/jan-quaeyhaegens.png";
import MLWPic48 from "../assets/fighter/men-lightweight/john-mitchell.png";
// import MLWPic49 from "../assets/fighter/men-lightweight/krzyszlof-mendlewski.png";
// import MLWPic50 from "../assets/fighter/men-lightweight/mandel-nallo.png";
import MLWPic51 from "../assets/fighter/men-lightweight/mohamed-tarek-mohey.png";
import MLWPic52 from "../assets/fighter/men-lightweight/murad-umachiev.png";
import MLWPic53 from "../assets/fighter/men-lightweight/romario-orozco.png";
import MLWPic54 from "../assets/fighter/men-lightweight/tofiq-musayev.png";
import MLWPic55 from "../assets/fighter/men-lightweight/vladislav-rudnev.png";
import MLWPic56 from "../assets/fighter/men-lightweight/saul-rogers.png";
import MLWPic57 from "../assets/fighter/men-lightweight/sage-northcutt.png";
import MLWPic58 from "../assets/fighter/men-lightweight/patricky-pitbull.png";

import MFWPic1 from "../assets/fighter/men-featherweight/pic1.png";
import MFWPic2 from "../assets/fighter/men-featherweight/pic2.png";
import MFWPic3 from "../assets/fighter/men-featherweight/pic3.png";
import MFWPic4 from "../assets/fighter/men-featherweight/pic4.png";
import MFWPic5 from "../assets/fighter/men-featherweight/pic5.png";
import MFWPic6 from "../assets/fighter/men-featherweight/pic6.png";
import MFWPic7 from "../assets/fighter/men-featherweight/pic7.png";
// import MFWPic8 from "../assets/fighter/men-featherweight/pic8.png";
import MFWPic9 from "../assets/fighter/men-featherweight/pic9.png";
import MFWPic10 from "../assets/fighter/men-featherweight/pic10.png";
import MFWPic11 from "../assets/fighter/men-featherweight/pic11.jpg";
import MFWPic12 from "../assets/fighter/men-featherweight/chad_mendes.png";
import MFWPic13 from "../assets/fighter/men-featherweight/chris-wade.png";
import MFWPic14 from "../assets/fighter/men-featherweight/huseyn-aliyev.png";
import MFWPic15 from "../assets/fighter/men-featherweight/mike-grundy.png";
import MFWPic16 from "../assets/fighter/men-featherweight/artem-belakh.png";
import MFWPic17 from "../assets/fighter/men-featherweight/edilson-teixeira.png";
import MFWPic18 from "../assets/fighter/men-featherweight/emrah-sonmez.png";
import MFWPic19 from "../assets/fighter/men-featherweight/jamie-siraj.png";
import MFWPic20 from "../assets/fighter/men-featherweight/julio-arce.png";
import MFWPic21 from "../assets/fighter/men-featherweight/leonardo-diniz.png";
import MFWPic22 from "../assets/fighter/men-featherweight/lucas-miletich.png";
import MFWPic23 from "../assets/fighter/men-featherweight/maike-linhares.png";
import MFWPic24 from "../assets/fighter/men-featherweight/marco-beltran.png";
import MFWPic25 from "../assets/fighter/men-featherweight/matthew-wagy.png";
import MFWPic26 from "../assets/fighter/men-featherweight/pedro-colman.png";
import MFWPic27 from "../assets/fighter/men-featherweight/rakhim-midaev.png";
// import MFWPic28 from "../assets/fighter/men-featherweight/tariq-ismail.png";
import MFWPic29 from "../assets/fighter/men-featherweight/tyler-diamond.png";
import MFWPic30 from "../assets/fighter/men-featherweight/zygimantas-ramaska.png";
import MFWPic31 from "../assets/fighter/men-featherweight/carlos-alexandre.png";
import MFWPic32 from "../assets/fighter/men-featherweight/diego-davella.png";
import MFWPic33 from "../assets/fighter/men-featherweight/donny-matos.png";
import MFWPic34 from "../assets/fighter/men-featherweight/kenny-mokhonoana.png";
import MFWPic35 from "../assets/fighter/men-featherweight/leonardo-morales.png";
import MFWPic36 from "../assets/fighter/men-featherweight/lucas-caio.png";
import MFWPic37 from "../assets/fighter/men-featherweight/thomas-assis.png";
import MFWPic38 from "../assets/fighter/men-featherweight/walter-cagliandro.png";
import MFWPic39 from "../assets/fighter/men-featherweight/ylies-djiroun.png";
import MFWPic40 from "../assets/fighter/men-featherweight/pedro-carvalho.png";
import MFWPic41 from "../assets/fighter/men-featherweight/brett-johns.png";
import MFWPic42 from "../assets/fighter/men-featherweight/kai-kamaka.png";
import MFWPic43 from "../assets/fighter/men-featherweight/khumoyun-tukhtamuradov.png";
import MFWPic44 from "../assets/fighter/men-featherweight/walter-cogliandro.png";

import MBWPic1 from "../assets/fighter/men-bantamweight/pic1.png";
import MBWPic2 from "../assets/fighter/men-bantamweight/pic2.png";
import MBWPic3 from "../assets/fighter/men-bantamweight/pic3.png";
import MBWPic4 from "../assets/fighter/men-bantamweight/pic4.png";
import MBWPic5 from "../assets/fighter/men-bantamweight/pic5.png";
import MBWPic6 from "../assets/fighter/men-bantamweight/pic6.png";
import MBWPic7 from "../assets/fighter/men-bantamweight/pic7.png";
import MBWPic8 from "../assets/fighter/men-bantamweight/pic8.jpg";
import MBWPic9 from "../assets/fighter/men-bantamweight/brian_kelleher.png";
import MBWPic10 from "../assets/fighter/men-bantamweight/timur_valiev.png";
import MBWPic11 from "../assets/fighter/men-bantamweight/andre-soukhamthath.png";
import MBWPic12 from "../assets/fighter/men-bantamweight/matheus-nicolau.png";
import MBWPic13 from "../assets/fighter/men-bantamweight/ali-wasuk.png";
import MBWPic14 from "../assets/fighter/men-bantamweight/andre-barquero.png";
import MBWPic15 from "../assets/fighter/men-bantamweight/dileno-lopes.png";
import MBWPic16 from "../assets/fighter/men-bantamweight/felipe-zacaron.png";
import MBWPic17 from "../assets/fighter/men-bantamweight/josh-hill.png";
import MBWPic18 from "../assets/fighter/men-bantamweight/lasha-abramishvili.png";
import MBWPic19 from "../assets/fighter/men-bantamweight/marcelo-guarilha.png";
import MBWPic20 from "../assets/fighter/men-bantamweight/raphael-assuncao.png";
import MBWPic21 from "../assets/fighter/men-bantamweight/ray-borg.png";
import MBWPic22 from "../assets/fighter/men-bantamweight/saidyokub-kakhramonov.png";
import MBWPic23 from "../assets/fighter/men-bantamweight/xian-ji.png";
import MBWPic24 from "../assets/fighter/men-bantamweight/altamiro-de-jesus.png";
import MBWPic25 from "../assets/fighter/men-bantamweight/edson-lourenco.png";
import MBWPic26 from "../assets/fighter/men-bantamweight/farbod-iran-nezad.png";
import MBWPic27 from "../assets/fighter/men-bantamweight/gamlet-aslanov.png";
// import MBWPic28 from "../assets/fighter/men-bantamweight/paulo-portillo.png";
import MBWPic29 from "../assets/fighter/men-bantamweight/marlon-moraes.png";
import MBWPic30 from "../assets/fighter/men-bantamweight/eric-shelton.png";
import MBWPic31 from "../assets/fighter/men-bantamweight/victor-madrigal.png";
import MBWPic32 from "../assets/fighter/men-bantamweight/ali-guliev.png";
import MBWPic33 from "../assets/fighter/men-bantamweight/darrion-caldwell.png";
import MBWPic34 from "../assets/fighter/men-bantamweight/jose-maria-tome.png";
import MBWPic35 from "../assets/fighter/men-bantamweight/louie-sanoudakis.png";
import MBWPic36 from "../assets/fighter/men-bantamweight/mariano-pipi.png";
import MBWPic37 from "../assets/fighter/men-bantamweight/miguel-angel-sosa.png";
import MBWPic38 from "../assets/fighter/men-bantamweight/ricky-bandejas.png";
// import MBWPic39 from "../assets/fighter/men-bantamweight/razhabali-shaydullaev.png";
import MBWPic40 from "../assets/fighter/men-bantamweight/zviad-lazishvili.png";

import WBWPic1 from "../assets/fighter/women-bantamweight/pic1.jpg";
// import WBWPic2 from "../assets/fighter/women-bantamweight/pic2.jpg";
import WBWPic3 from "../assets/fighter/women-bantamweight/pic3.jpg";
import WBWPic4 from "../assets/fighter/women-bantamweight/pic4.jpg";
import WBWPic5 from "../assets/fighter/women-bantamweight/pic5.jpg";
import WBWPic6 from "../assets/fighter/women-bantamweight/luz-vazquez.png";
import WBWPic7 from "../assets/fighter/women-bantamweight/talita-bernardo.png";
// import WBWPic8 from "../assets/fighter/women-bantamweight/tamires-vidal.png";
import WBWPic9 from "../assets/fighter/women-bantamweight/thalita-diniz.png";
// import WBWPic10 from "../assets/fighter/women-bantamweight/bianca-daimoni.png";
// import WBWPic11 from "../assets/fighter/women-bantamweight/brittney-cloudy.png";
// import WBWPic12 from "../assets/fighter/women-bantamweight/fernanda-barbosa.png";
// import WBWPic13 from "../assets/fighter/women-bantamweight/gisele-moreira.png";
// import WBWPic14 from "../assets/fighter/women-bantamweight/juliet-chukwu.png";
// import WBWPic15 from "../assets/fighter/women-bantamweight/zurina-turrey.png";
import WBWPic16 from "../assets/fighter/women-bantamweight/alana-cook.png";
import WBWPic17 from "../assets/fighter/women-bantamweight/lina-fayyad.png";
import WBWPic18 from "../assets/fighter/women-bantamweight/shanna-young.png";
import WBWPic19 from "../assets/fighter/women-bantamweight/vladlena-petrova.png";
import WBWPic20 from "../assets/fighter/women-bantamweight/emilse-chirino.png";
import WBWPic21 from "../assets/fighter/women-bantamweight/sabriye-sengul.png";
import WBWPic22 from "../assets/fighter/women-bantamweight/alejandra-lara.png";
import WBWPic23 from "../assets/fighter/women-bantamweight/arlene-blencowe.png";
import WBWPic24 from "../assets/fighter/women-bantamweight/liana-jojua.png";
import WBWPic25 from "../assets/fighter/women-bantamweight/paula-cristina.png";
// import WBWPic26 from "../assets/fighter/women-bantamweight/holly-holm.png";
// import WBWPic27 from "../assets/fighter/women-bantamweight/olga-rubin.png";

import WFWPic1 from "../assets/fighter/women-flyweight/pic1.jpg";
import WFWPic2 from "../assets/fighter/women-flyweight/pic2.jpg";
import WFWPic3 from "../assets/fighter/women-flyweight/pic3.jpg";
import WFWPic4 from "../assets/fighter/women-flyweight/antonia-silvaneide.png";
import WFWPic5 from "../assets/fighter/women-flyweight/janaisa-morandin.png";
import WFWPic6 from "../assets/fighter/women-flyweight/mara-borella.png";
import WFWPic7 from "../assets/fighter/women-flyweight/paige-vanzant.png";
// import WFWPic8 from "../assets/fighter/women-flyweight/andressa-rocha.png";
// import WFWPic9 from "../assets/fighter/women-flyweight/cynthia-calvillo.png";
// import WFWPic10 from "../assets/fighter/women-flyweight/karolina-wojcik.png";
// import WFWPic11 from "../assets/fighter/women-flyweight/maira-mazar.png";
import WFWPic12 from "../assets/fighter/women-flyweight/karolina-owczarz.png";
import WFWPic13 from "../assets/fighter/women-flyweight/faine-mesquita.png";
import WFWPic14 from "../assets/fighter/women-flyweight/justine-kish.png";
// import WFWPic15 from "../assets/fighter/women-flyweight/kelly-mckay.png";
import WFWPic16 from "../assets/fighter/women-flyweight/mariya-agapova.png";
import WFWPic17 from "../assets/fighter/women-flyweight/veronika-borisova.png";
import WFWPic18 from "../assets/fighter/women-flyweight/elin-oberg.png";
import WFWPic19 from "../assets/fighter/women-flyweight/maiju-suotama.png";
import WFWPic20 from "../assets/fighter/women-flyweight/camila-reynoso.png";

import WSWPic1 from "../assets/fighter/women-strawweight/pic1.jpg";
import WSWPic2 from "../assets/fighter/women-strawweight/pic2.jpg";
import WSWPic3 from "../assets/fighter/women-strawweight/pic3.jpg";
import WSWPic4 from "../assets/fighter/women-strawweight/pic4.jpg";
import WSWPic5 from "../assets/fighter/women-strawweight/pic5.jpg";
import WSWPic6 from "../assets/fighter/women-strawweight/pic6.jpg";
import WSWPic7 from "../assets/fighter/women-strawweight/eva-dourthe.png";
import WSWPic8 from "../assets/fighter/women-strawweight/isabela-de-padua.png";
import WSWPic9 from "../assets/fighter/women-strawweight/isis-verbeek.png";
import WSWPic11 from "../assets/fighter/women-strawweight/livinha-souza.png";
import WSWPic12 from "../assets/fighter/women-strawweight/maria-luiza-de-abreu.png";
import WSWPic13 from "../assets/fighter/women-strawweight/natalia-kuziutina.png";
import WSWPic14 from "../assets/fighter/women-strawweight/pamela-mara.png";
import WSWPic15 from "../assets/fighter/women-strawweight/yasmin-castanho.png";
import WSWPic16 from "../assets/fighter/women-strawweight/brenda-gottig.png";
// import WSWPic17 from "../assets/fighter/women-strawweight/melissa-amaya.png";
// import WSWPic18 from "../assets/fighter/women-strawweight/randi-field.png";
import WSWPic19 from "../assets/fighter/women-strawweight/anita-bekus.png";
import WSWPic20 from "../assets/fighter/women-strawweight/joice-mara.png";
import WSWPic21 from "../assets/fighter/women-strawweight/erika-sousa.png";
import WSWPic22 from "../assets/fighter/women-strawweight/maiken-aannerud.png";
// import WSWPic23 from "../assets/fighter/women-strawweight/tamires-vidal.png";
import WSWPic24 from "../assets/fighter/women-strawweight/ilima-lei-macfarlane.png";
import WSWPic25 from "../assets/fighter/women-strawweight/jada-ketley.png";
// import WSWPic26 from "../assets/fighter/women-strawweight/karolina-wojcik.png";
import WSWPic27 from "../assets/fighter/women-strawweight/kelly-staddon.png";
import WSWPic28 from "../assets/fighter/women-strawweight/samantha-jean-francois.png";
import WSWPic29 from "../assets/fighter/women-strawweight/cory-mckenna.png";
import WSWPic30 from "../assets/fighter/women-strawweight/hayley-valentine.png";
import WSWPic31 from "../assets/fighter/women-strawweight/maria-henderson.png";
import WSWPic32 from "../assets/fighter/women-strawweight/josefine-knutsson.png";

import WFEWPic1 from "../assets/fighter/women-featherweight/fernanda-guersone.png";
import WFEWPic2 from "../assets/fighter/women-featherweight/julia-budd.png";
import WFEWPic3 from "../assets/fighter/women-featherweight/julia-dorny.png";
import WFEWPic4 from "../assets/fighter/women-featherweight/lorrany-santos.png";
import WFEWPic5 from "../assets/fighter/women-featherweight/evelyn-martins.png";
import WFEWPic6 from "../assets/fighter/women-featherweight/mariza-loch.png";
// import WFEWPic7 from "../assets/fighter/women-featherweight/leslie-smith.png";
import WFEWPic8 from "../assets/fighter/women-featherweight/aspen-ladd.png";
import WFEWPic9 from "../assets/fighter/women-featherweight/elina-kallionidou.png";
// import WFEWPic10 from "../assets/fighter/women-featherweight/jady-menezes.png";
import WFEWPic11 from "../assets/fighter/women-featherweight/cat-zingano.png";

import WAWPic1 from "../assets/fighter/women-atomweight/claire-lopez.png";
import WAWPic2 from "../assets/fighter/women-atomweight/jessica-aguilar.png";
import WAWPic3 from "../assets/fighter/women-atomweight/shino-vanhoose.png";
// import WAWPic4 from "../assets/fighter/women-atomweight/anastasia-nikolakakos.png";
import WAWPic5 from "../assets/fighter/women-atomweight/marisa-messer-belenchia.png";

const imageMapping = {
  MHWPic1: MHWPic1,
  MHWPic2: MHWPic2,
  MHWPic3: MHWPic3,
  MHWPic4: MHWPic4,
  MHWPic5: MHWPic5,
  MHWPic6: MHWPic6,
  MHWPic7: MHWPic7,
  MHWPic8: MHWPic8,
  MHWPic9: MHWPic9,
  MHWPic10: MHWPic10,
  MHWPic11: MHWPic11,
  MHWPic12: MHWPic12,
  MHWPic13: MHWPic13,
  MHWPic14: MHWPic14,
  MHWPic15: MHWPic15,
  MHWPic16: MHWPic16,
  MHWPic17: MHWPic17,
  MHWPic18: MHWPic18,
  MHWPic19: MHWPic19,
  MHWPic20: MHWPic20,
  MHWPic21: MHWPic21,
  MHWPic22: MHWPic22,
  MHWPic23: MHWPic23,
  MHWPic24: MHWPic24,
  MHWPic25: MHWPic25,
  MHWPic26: MHWPic26,
  MHWPic27: MHWPic27,
  MHWPic28: MHWPic28,
  MHWPic29: MHWPic29,
  MHWPic30: MHWPic30,
  MHWPic31: MHWPic31,
  MHWPic32: MHWPic32,
  MHWPic33: MHWPic33,
  MHWPic34: MHWPic34,
  MHWPic35: MHWPic35,

  MLHWPic1: MLHWPic1,
  MLHWPic2: MLHWPic2,
  MLHWPic3: MLHWPic3,
  MLHWPic4: MLHWPic4,
  MLHWPic5: MLHWPic5,
  MLHWPic6: MLHWPic6,
  MLHWPic7: MLHWPic7,
  MLHWPic8: MLHWPic8,
  MLHWPic9: MLHWPic9,
  MLHWPic10: MLHWPic10,
  MLHWPic11: MLHWPic11,
  MLHWPic12: MLHWPic12,
  MLHWPic13: MLHWPic13,
  MLHWPic14: MLHWPic14,
  MLHWPic15: MLHWPic15,
  MLHWPic16: MLHWPic16,
  MLHWPic17: MLHWPic17,
  MLHWPic18: MLHWPic18,
  MLHWPic19: MLHWPic19,
  MLHWPic20: MLHWPic20,
  MLHWPic21: MLHWPic21,
  // MLHWPic22: MLHWPic22,
  MLHWPic23: MLHWPic23,
  MLHWPic24: MLHWPic24,
  // MLHWPic25: MLHWPic25,
  MLHWPic26: MLHWPic26,
  MLHWPic27: MLHWPic27,
  // MLHWPic28: MLHWPic28,
  MLHWPic29: MLHWPic29,
  MLHWPic30: MLHWPic30,
  MLHWPic31: MLHWPic31,
  MLHWPic32: MLHWPic32,
  MLHWPic33: MLHWPic33,
  MLHWPic34: MLHWPic34,

  MMWPic1: MMWPic1,
  MMWPic2: MMWPic2,
  MMWPic3: MMWPic3,
  MMWPic4: MMWPic4,
  MMWPic5: MMWPic5,
  // MMWPic6: MMWPic6,
  MMWPic7: MMWPic7,
  MMWPic8: MMWPic8,
  MMWPic9: MMWPic9,
  MMWPic10: MMWPic10,
  // MMWPic11: MMWPic11,
  MMWPic12: MMWPic12,
  // MMWPic13: MMWPic13,
  MMWPic14: MMWPic14,
  MMWPic15: MMWPic15,
  // MMWPic16: MMWPic16,
  // MMWPic17: MMWPic17,
  MMWPic18: MMWPic18,
  MMWPic19: MMWPic19,
  MMWPic20: MMWPic20,
  MMWPic21: MMWPic21,
  MMWPic22: MMWPic22,
  MMWPic23: MMWPic23,
  MMWPic24: MMWPic24,
  MMWPic25: MMWPic25,
  MMWPic26: MMWPic26,
  MMWPic27: MMWPic27,
  MMWPic28: MMWPic28,
  MMWPic29: MMWPic29,
  MMWPic30: MMWPic30,
  MMWPic31: MMWPic31,
  MMWPic32: MMWPic32,
  MMWPic33: MMWPic33,
  MMWPic34: MMWPic34,
  MMWPic35: MMWPic35,
  MMWPic36: MMWPic36,
  MMWPic37: MMWPic37,
  MMWPic38: MMWPic38,
  MMWPic39: MMWPic39,

  MWWPic1: MWWPic1,
  MWWPic2: MWWPic2,
  // MWWPic3: MWWPic3,
  MWWPic4: MWWPic4,
  MWWPic5: MWWPic5,
  MWWPic6: MWWPic6,
  MWWPic7: MWWPic7,
  MWWPic8: MWWPic8,
  MWWPic9: MWWPic9,
  MWWPic10: MWWPic10,
  MWWPic11: MWWPic11,
  MWWPic12: MWWPic12,
  MWWPic13: MWWPic13,
  MWWPic14: MWWPic14,
  MWWPic15: MWWPic15,
  MWWPic16: MWWPic16,
  MWWPic17: MWWPic17,
  MWWPic18: MWWPic18,
  MWWPic19: MWWPic19,
  MWWPic20: MWWPic20,
  MWWPic21: MWWPic21,
  MWWPic22: MWWPic22,
  MWWPic23: MWWPic23,
  MWWPic24: MWWPic24,
  MWWPic25: MWWPic25,
  // MWWPic26: MWWPic26,
  MWWPic27: MWWPic27,
  MWWPic28: MWWPic28,
  MWWPic29: MWWPic29,
  MWWPic30: MWWPic30,
  MWWPic31: MWWPic31,
  MWWPic32: MWWPic32,
  MWWPic33: MWWPic33,
  MWWPic34: MWWPic34,
  MWWPic35: MWWPic35,
  MWWPic36: MWWPic36,
  MWWPic37: MWWPic37,
  MWWPic38: MWWPic38,
  // MWWPic39: MWWPic39,
  // MWWPic40: MWWPic40,
  MWWPic41: MWWPic41,
  MWWPic42: MWWPic42,
  MWWPic43: MWWPic43,
  // MWWPic44: MWWPic44,
  MWWPic45: MWWPic45,
  MWWPic46: MWWPic46,
  // MWWPic47: MWWPic47,
  MWWPic48: MWWPic48,
  MWWPic49: MWWPic49,

  MLWPic1: MLWPic1,
  MLWPic2: MLWPic2,
  MLWPic3: MLWPic3,
  MLWPic4: MLWPic4,
  MLWPic5: MLWPic5,
  MLWPic6: MLWPic6,
  MLWPic7: MLWPic7,
  MLWPic8: MLWPic8,
  MLWPic9: MLWPic9,
  MLWPic10: MLWPic10,
  MLWPic11: MLWPic11,
  MLWPic12: MLWPic12,
  MLWPic13: MLWPic13,
  MLWPic14: MLWPic14,
  MLWPic15: MLWPic15,
  MLWPic16: MLWPic16,
  MLWPic17: MLWPic17,
  MLWPic18: MLWPic18,
  MLWPic19: MLWPic19,
  MLWPic20: MLWPic20,
  MLWPic21: MLWPic21,
  MLWPic22: MLWPic22,
  MLWPic23: MLWPic23,
  MLWPic24: MLWPic24,
  MLWPic25: MLWPic25,
  MLWPic26: MLWPic26,
  MLWPic27: MLWPic27,
  MLWPic28: MLWPic28,
  MLWPic29: MLWPic29,
  // MLWPic30: MLWPic30,
  MLWPic31: MLWPic31,
  MLWPic32: MLWPic32,
  MLWPic33: MLWPic33,
  MLWPic34: MLWPic34,
  MLWPic35: MLWPic35,
  MLWPic36: MLWPic36,
  MLWPic37: MLWPic37,
  MLWPic38: MLWPic38,
  MLWPic39: MLWPic39,
  MLWPic40: MLWPic40,
  MLWPic41: MLWPic41,
  MLWPic42: MLWPic42,
  MLWPic43: MLWPic43,
  MLWPic44: MLWPic44,
  MLWPic45: MLWPic45,
  MLWPic46: MLWPic46,
  // MLWPic47: MLWPic47,
  MLWPic48: MLWPic48,
  // MLWPic49: MLWPic49,
  // MLWPic50: MLWPic50,
  MLWPic51: MLWPic51,
  MLWPic52: MLWPic52,
  MLWPic53: MLWPic53,
  MLWPic54: MLWPic54,
  MLWPic55: MLWPic55,
  MLWPic56: MLWPic56,
  MLWPic57: MLWPic57,
  MLWPic58: MLWPic58,

  MFWPic1: MFWPic1,
  MFWPic2: MFWPic2,
  MFWPic3: MFWPic3,
  MFWPic4: MFWPic4,
  MFWPic5: MFWPic5,
  MFWPic6: MFWPic6,
  MFWPic7: MFWPic7,
  // MFWPic8: MFWPic8,
  MFWPic9: MFWPic9,
  MFWPic10: MFWPic10,
  MFWPic11: MFWPic11,
  MFWPic12: MFWPic12,
  MFWPic13: MFWPic13,
  MFWPic14: MFWPic14,
  MFWPic15: MFWPic15,
  MFWPic16: MFWPic16,
  MFWPic17: MFWPic17,
  MFWPic18: MFWPic18,
  MFWPic19: MFWPic19,
  MFWPic20: MFWPic20,
  MFWPic21: MFWPic21,
  MFWPic22: MFWPic22,
  MFWPic23: MFWPic23,
  MFWPic24: MFWPic24,
  MFWPic25: MFWPic25,
  MFWPic26: MFWPic26,
  MFWPic27: MFWPic27,
  // MFWPic28: MFWPic28,
  MFWPic29: MFWPic29,
  MFWPic30: MFWPic30,
  MFWPic31: MFWPic31,
  MFWPic32: MFWPic32,
  MFWPic33: MFWPic33,
  MFWPic34: MFWPic34,
  MFWPic35: MFWPic35,
  MFWPic36: MFWPic36,
  MFWPic37: MFWPic37,
  MFWPic38: MFWPic38,
  MFWPic39: MFWPic39,
  MFWPic40: MFWPic40,
  MFWPic41: MFWPic41,
  MFWPic42: MFWPic42,
  MFWPic43: MFWPic43,
  MFWPic44: MFWPic44,

  MBWPic1: MBWPic1,
  MBWPic2: MBWPic2,
  MBWPic3: MBWPic3,
  MBWPic4: MBWPic4,
  MBWPic5: MBWPic5,
  MBWPic6: MBWPic6,
  MBWPic7: MBWPic7,
  MBWPic8: MBWPic8,
  MBWPic9: MBWPic9,
  MBWPic10: MBWPic10,
  MBWPic11: MBWPic11,
  MBWPic12: MBWPic12,
  MBWPic13: MBWPic13,
  MBWPic14: MBWPic14,
  MBWPic15: MBWPic15,
  MBWPic16: MBWPic16,
  MBWPic17: MBWPic17,
  MBWPic18: MBWPic18,
  MBWPic19: MBWPic19,
  MBWPic20: MBWPic20,
  MBWPic21: MBWPic21,
  MBWPic22: MBWPic22,
  MBWPic23: MBWPic23,
  MBWPic24: MBWPic24,
  MBWPic25: MBWPic25,
  MBWPic26: MBWPic26,
  MBWPic27: MBWPic27,
  // MBWPic28: MBWPic28,
  MBWPic29: MBWPic29,
  MBWPic30: MBWPic30,
  MBWPic31: MBWPic31,
  MBWPic32: MBWPic32,
  MBWPic33: MBWPic33,
  MBWPic34: MBWPic34,
  MBWPic35: MBWPic35,
  MBWPic36: MBWPic36,
  MBWPic37: MBWPic37,
  MBWPic38: MBWPic38,
  // MBWPic39: MBWPic39,
  MBWPic40: MBWPic40,

  WBWPic1: WBWPic1,
  // WBWPic2: WBWPic2,
  WBWPic3: WBWPic3,
  WBWPic4: WBWPic4,
  WBWPic5: WBWPic5,
  WBWPic6: WBWPic6,
  WBWPic7: WBWPic7,
  // WBWPic8: WBWPic8,
  WBWPic9: WBWPic9,
  // WBWPic10: WBWPic10,
  // WBWPic11: WBWPic11,
  // WBWPic12: WBWPic12,
  // WBWPic13: WBWPic13,
  // WBWPic14: WBWPic14,
  // WBWPic15: WBWPic15,
  WBWPic16: WBWPic16,
  WBWPic17: WBWPic17,
  WBWPic18: WBWPic18,
  WBWPic19: WBWPic19,
  WBWPic20: WBWPic20,
  WBWPic21: WBWPic21,
  WBWPic22: WBWPic22,
  WBWPic23: WBWPic23,
  WBWPic24: WBWPic24,
  WBWPic25: WBWPic25,
  // WBWPic26: WBWPic26,
  // WBWPic27: WBWPic27,

  WFWPic1: WFWPic1,
  WFWPic2: WFWPic2,
  WFWPic3: WFWPic3,
  WFWPic4: WFWPic4,
  WFWPic5: WFWPic5,
  WFWPic6: WFWPic6,
  WFWPic7: WFWPic7,
  // WFWPic8: WFWPic8,
  // WFWPic9: WFWPic9,
  // WFWPic10: WFWPic10,
  // WFWPic11: WFWPic11,
  WFWPic12: WFWPic12,
  WFWPic13: WFWPic13,
  WFWPic14: WFWPic14,
  // WFWPic15: WFWPic15,
  WFWPic16: WFWPic16,
  WFWPic17: WFWPic17,
  WFWPic18: WFWPic18,
  WFWPic19: WFWPic19,
  WFWPic20: WFWPic20,

  WSWPic1: WSWPic1,
  WSWPic2: WSWPic2,
  WSWPic3: WSWPic3,
  WSWPic4: WSWPic4,
  WSWPic5: WSWPic5,
  WSWPic6: WSWPic6,
  WSWPic7: WSWPic7,
  WSWPic8: WSWPic8,
  WSWPic9: WSWPic9,
  WSWPic11: WSWPic11,
  WSWPic12: WSWPic12,
  WSWPic13: WSWPic13,
  WSWPic14: WSWPic14,
  WSWPic15: WSWPic15,
  WSWPic16: WSWPic16,
  // WSWPic17: WSWPic17,
  // WSWPic18: WSWPic18,
  WSWPic19: WSWPic19,
  WSWPic20: WSWPic20,
  WSWPic21: WSWPic21,
  WSWPic22: WSWPic22,
  // WSWPic23: WSWPic23,
  WSWPic24: WSWPic24,
  WSWPic25: WSWPic25,
  // WSWPic26: WSWPic26,
  WSWPic27: WSWPic27,
  WSWPic28: WSWPic28,
  WSWPic29: WSWPic29,
  WSWPic30: WSWPic30,
  WSWPic31: WSWPic31,
  WSWPic32: WSWPic32,

  WFEWPic1: WFEWPic1,
  WFEWPic2: WFEWPic2,
  WFEWPic3: WFEWPic3,
  WFEWPic4: WFEWPic4,
  WFEWPic5: WFEWPic5,
  WFEWPic6: WFEWPic6,
  // WFEWPic7: WFEWPic7,
  WFEWPic8: WFEWPic8,
  // WFEWPic10: WFEWPic10,
  WFEWPic9: WFEWPic9,
  WFEWPic11: WFEWPic11,

  WAWPic1: WAWPic1,
  WAWPic2: WAWPic2,
  WAWPic3: WAWPic3,
  // WAWPic4: WAWPic4,
  WAWPic5: WAWPic5,
};

const womenContent = {
  women: {
    divisions: {
      // featherweight: {
      //   label: "Featherweight",
      //   value: 1,
      //   fighters: [

      //   ],
      // },
      bantamWeight: {
        label: "GFL Bantamweight",
        value: 1,
        fighters: [
          { src: "WFEWPic1", name: "fernanda guersone" },
          { src: "WFEWPic2", name: "julia budd" },
          { src: "WFEWPic3", name: "julia dorny" },
          { src: "WFEWPic4", name: "lorrany santos" },
          { src: "WFEWPic5", name: "evelyn martins" },
          { src: "WFEWPic6", name: "mariza loch" },
          // { src: "WFEWPic7", name: "leslie smith" },
          { src: "WFEWPic8", name: "aspen ladd" },
          { src: "WFEWPic9", name: "Elina Kallionidou" },
          // { src: "WFEWPic10", name: "Jady Menezes" },
          { src: "WFEWPic11", name: "cat zingano" },

          { src: "WBWPic1", name: "Alexa Conners" },
          // { "src": "WBWPic2", "name": "Kalindra Faria" },
          { src: "WBWPic3", name: "Pannie Kianzad" },
          { src: "WBWPic4", name: "Tonya Evinger" },
          { src: "WBWPic5", name: "Queila Braga" },
          { src: "WBWPic6", name: "Luz Vazquez" },
          { src: "WBWPic7", name: "Talita Bernardo" },
          // { "src": "WBWPic8", "name": "Tamires Vidal" },
          { src: "WBWPic9", name: "Thalita Diniz" },
          // { "src": "WBWPic10", "name": "Bianca Daimoni" },
          // { "src": "WBWPic11", "name": "Brittney Cloudy" },
          // { "src": "WBWPic12", "name": "Fernanda Barbosa" },
          // { "src": "WBWPic13", "name": "Gisele Moreira" },
          // { "src": "WBWPic14", "name": "Juliet Chukwu" },
          // { "src": "WBWPic15", "name": "Zurina Turrey" },
          { src: "WBWPic16", name: "alana cook" },
          { src: "WBWPic17", name: "lina fayyad" },
          { src: "WBWPic18", name: "shanna young" },
          { src: "WBWPic19", name: "vladlena petrova" },
          { src: "WBWPic20", name: "Emilse Chirino" },
          { src: "WBWPic21", name: "Sabriye Sengul" },
          { src: "WBWPic22", name: "alejandra lara" },
          { src: "WBWPic23", name: "arlene blencowe" },
          { src: "WBWPic24", name: "liana jojua" },
          { src: "WBWPic25", name: "paula cristina" },
          ,
          // { src: "WBWPic26", name: "holly holm" },
          // { src: "WBWPic27", name: "olga rubin" },
        ],
      },
      // flyWeight: {
      //   label: "Flyweight",
      //   value: 3,
      //   fighters: [

      //   ],
      // },
      strawWeight: {
        label: "GFL Strawweight",
        value: 2,
        fighters: [
          { src: "WFWPic1", name: "Chiara Penco" },
          { src: "WFWPic2", name: "Miao Ding" },
          { src: "WFWPic3", name: "Kalindra Faria" },
          { src: "WFWPic4", name: "Antonia Silvaneide" },
          { src: "WFWPic5", name: "Janaisa Morandin" },
          { src: "WFWPic6", name: "Mara Borella" },
          { src: "WFWPic7", name: "Paige Vanzant" },
          // { "src": "WFWPic8", "name": "Andressa Rocha" },
          // { "src": "WFWPic9", "name": "cynthia calvillo" },
          // { "src": "WFWPic10", "name": "karolina wojcik" },
          // { "src": "WFWPic11", "name": "maira mazar" },
          { src: "WFWPic12", name: "karolina owczarz" },
          { src: "WFWPic13", name: "faine mesquita" },
          { src: "WFWPic14", name: "justine kish" },
          // { src: "WFWPic15", name: "kelly mckay" },
          { src: "WFWPic16", name: "mariya agapova" },
          { src: "WFWPic17", name: "veronika borisova" },
          { src: "WFWPic18", name: "Elin Oberg" },
          { src: "WFWPic19", name: "Maiju Suotama" },
          { src: "WFWPic20", name: "camila reynoso" },
        ],
      },
      atomweight: {
        label: "GFL Atomweight",
        value: 3,
        fighters: [
          { src: "WSWPic1", name: "Bi Nguyen" },
          { src: "WSWPic2", name: "Viviane Pereira" },
          { src: "WSWPic3", name: "Jessica Penne" },
          { src: "WSWPic4", name: "Silvania Monteiro" },
          { src: "WSWPic5", name: "Hanna Goldy" },
          { src: "WSWPic6", name: "Kayla Hracho" },
          { src: "WSWPic7", name: "Eva Dourthe" },
          { src: "WSWPic8", name: "isabela de padua" },
          { src: "WSWPic9", name: "isis verbeek" },
          { src: "WSWPic11", name: "livinha souza" },
          { src: "WSWPic12", name: "maria luiza de abreu" },
          { src: "WSWPic13", name: "natalia kuziutina" },
          { src: "WSWPic14", name: "pamela mara" },
          { src: "WSWPic15", name: "yasmin castanho" },
          { src: "WSWPic16", name: "brenda gottig" },
          // { "src": "WSWPic17", "name": "melissa amaya" },
          // { "src": "WSWPic18", "name": "randi field" },
          { src: "WSWPic19", name: "anita bekus" },
          { src: "WSWPic20", name: "joice mara" },
          { src: "WSWPic21", name: "erika sousa" },
          { src: "WSWPic22", name: "maiken aannerud" },
          // { "src": "WSWPic23", "name": "tamires vidal" },
          { src: "WSWPic24", name: "ilima lei macfarlane" },
          { src: "WSWPic25", name: "jada ketley" },
          // { src: "WSWPic26", name: "karolina wojcik" },
          { src: "WSWPic27", name: "kelly staddon" },
          { src: "WSWPic28", name: "samantha jean francois" },
          { src: "WSWPic29", name: "cory mckenna" },
          { src: "WSWPic30", name: "Hayley Valentine" },
          { src: "WSWPic31", name: "Maria Henderson" },
          { src: "WSWPic32", name: "josefine knutsson" },

          { src: "WAWPic1", name: "claire lopez" },
          { src: "WAWPic2", name: "jessica aguilar" },
          { src: "WAWPic3", name: "Shino Vanhoose" },
          // { src: "WAWPic4", name: "anastasia nikolakakos" },
          { src: "WAWPic5", name: "marisa messer belenchia" },
        ],
      },
    },
  },
};

const menContent = {
  men: {
    divisions: {
      heavyWeight: {
        label: "Heavyweight",
        value: 1,
        fighters: [
          { src: "MHWPic1", name: "Alan Belcher" },
          { src: "MHWPic2", name: "Aleksandr Maslov" },
          { src: "MHWPic3", name: "Alexei Oleynik" },
          { src: "MHWPic4", name: "Andrei Arlovski" },
          { src: "MHWPic5", name: "Fabricio Werdum" },
          { src: "MHWPic6", name: "Frank Mir" },
          { src: "MHWPic7", name: "Greg Hardy" },
          { src: "MHWPic8", name: "Junior Dos Santos" },
          { src: "MHWPic9", name: "Oli Thompson" },
          { src: "MHWPic10", name: "Philipe Lins" },
          { src: "MHWPic11", name: "Robelis Despaigne" },
          { src: "MHWPic12", name: "Roggers Souza" },
          { src: "MHWPic13", name: "Stuart Austin" },
          { src: "MHWPic14", name: "Tanner Boser" },
          { src: "MHWPic15", name: "Todd Duffee" },
          { src: "MHWPic16", name: "fernando rodrigues jr" },
          { src: "MHWPic17", name: "mostafa salehizadeh" },
          { src: "MHWPic18", name: "christian larsen" },
          { src: "MHWPic19", name: "davion franklin" },
          { src: "MHWPic20", name: "guto inocente" },
          // {"src":"MHWPic21","name":"ilir latifi"},
          { src: "MHWPic22", name: "juan adams" },
          // {"src":"MHWPic23","name":"matheus scheffel"},
          { src: "MHWPic24", name: "oscar cota" },
          { src: "MHWPic25", name: "quentin domingos" },
          { src: "MHWPic26", name: "rakim cleveland" },
          { src: "MHWPic27", name: "said sowma" },
          { src: "MHWPic28", name: "thiago santos" },
          { src: "MHWPic29", name: "ahmed jabbar al rubaye" },
          { src: "MHWPic30", name: "jordan heiderman" },
          { src: "MHWPic31", name: "roman lukashevich" },
          { src: "MHWPic32", name: "ronald girones sago" },
          { src: "MHWPic33", name: "alexander soldatkin" },
          { src: "MHWPic34", name: "ali isaev" },
          { src: "MHWPic35", name: "bruno cappelozza" },
        ],
      },
      lightHeavyWeight: {
        label: "Light Heavyweight",
        value: 2,
        fighters: [
          { src: "MLHWPic1", name: "Alexander Gustafsson" },
          { src: "MLHWPic2", name: "Cleiton Silva" },
          { src: "MLHWPic3", name: "Emiliano Sordi" },
          { src: "MLHWPic4", name: "Rafael Carvalho" },
          { src: "MLHWPic5", name: "Rashad Evans" },
          { src: "MLHWPic6", name: "Da Woon Jung" },
          { src: "MLHWPic7", name: "Grant Neal" },
          { src: "MLHWPic8", name: "Ovince Saint Preux" },
          { src: "MLHWPic9", name: "Mauricio Rua" },
          { src: "MLHWPic10", name: "Omari Akhmedov" },
          // { "src": "MLHWPic11", "name": "yoel romero" },
          { src: "MLHWPic12", name: "ilir latifi" },
          { src: "MLHWPic13", name: "thiago santos" },
          { src: "MLHWPic14", name: "acacio dos santos" },
          { src: "MLHWPic15", name: "anton turkalj" },
          { src: "MLHWPic16", name: "caio machado" },
          { src: "MLHWPic17", name: "chris camozzi" },
          { src: "MLHWPic18", name: "hasan mezhiev" },
          { src: "MLHWPic19", name: "masoud safari" },
          // { "src": "MLHWPic20", "name": "omari akhmedov" },
          { src: "MLHWPic21", name: "ovince saint preux" },
          // { "src": "MLHWPic22", "name": "przemyslaw mysiala" },
          { src: "MLHWPic23", name: "ronny markes" },
          { src: "MLHWPic24", name: "yoel romero" },
          { src: "MLHWPic24", name: "yoel romero" },
          // { src: "MLHWPic25", name: "edinaldo novaes" },
          { src: "MLHWPic26", name: "jamelle jones" },
          { src: "MLHWPic27", name: "julius anglickas" },
          // { src: "MLHWPic28", name: "luis henrique" },
          { src: "MLHWPic29", name: "mateusz duczmal" },
          { src: "MLHWPic30", name: "roman lukashevich" },
          { src: "MLHWPic31", name: "devin clark" },
          { src: "MLHWPic32", name: "luis henrique da silva" },
          { src: "MLHWPic33", name: "norman parke" },
          { src: "MLHWPic34", name: "antonio carlos junior" },
          
        ],
      },
      middleWeight: {
        label: "Middleweight",
        value: 3,
        fighters: [
          { src: "MMWPic1", name: "Gegard Mousasi" },
          { src: "MMWPic2", name: "Chauncy Foxworth" },
          { src: "MMWPic3", name: "Jared Revel" },
          { src: "MMWPic4", name: "Glaico Franca" },
          { src: "MMWPic5", name: "Hector Lombard" },
          // { "src": "MMWPic6", "name": "Jozef Wittner"  },
          { src: "MMWPic7", name: "Kyle Daukaus" },
          { src: "MMWPic8", name: "Luke Rockhold" },
          { src: "MMWPic9", name: "Markus Perez" },
          { src: "MMWPic10", name: "Phillip Hawes" },
          // { "src": "MMWPic11", "name": "Wanderlei Silva" },
          { src: "MMWPic12", name: "Mariusz Ksiazkiewicz" },
          // { src: "MMWPic13", name: "andreas michailidis" },
          { src: "MMWPic14", name: "david branch" },
          { src: "MMWPic15", name: "douglas lima" },
          // { "src": "MMWPic16", "name": "jozef wittner" },
          // { src: "MMWPic17", name: "chris weidman" },
          { src: "MMWPic18", name: "uriah hall" },
          // {"src":"MMWPic19","name":"david branch"},
          { src: "MMWPic20", name: "dwight grant" },
          { src: "MMWPic21", name: "jack marshman" },
          { src: "MMWPic22", name: "john allan" },
          { src: "MMWPic23", name: "jorge bueno" },
          { src: "MMWPic24", name: "leandro silva" },
          { src: "MMWPic25", name: "lucas alsina" },
          { src: "MMWPic26", name: "mark stoddard" },
          { src: "MMWPic27", name: "nayib lopez" },
          { src: "MMWPic28", name: "simon marcus" },
          { src: "MMWPic29", name: "thomas lane" },
          // {"src":"MMWPic30","name":"yoel romero"}
          { src: "MMWPic31", name: "adis dadovic" },
          { src: "MMWPic32", name: "jonathan figueroa" },
          { src: "MMWPic33", name: "oton jasse" },
          { src: "MMWPic34", name: "pieter buist" },
          { src: "MMWPic35", name: "rodrigo cavalheiro" },
          { src: "MMWPic36", name: "tornike gigauri" },
          { src: "MMWPic37", name: "willker lemos" },
          { src: "MMWPic38", name: "zaza dokhnadze" },
          { src: "MMWPic39", name: "alan silva" },
          
        ],
      },
      welterWeight: {
        label: "Welterweight",
        value: 4,
        fighters: [
          { src: "MWWPic1", name: "Abubakar Nurmagomedov" },
          { src: "MWWPic2", name: "Austin Tweedy" },
          // { "src": "MWWPic3", "name": "Benson Henderson" },
          { src: "MWWPic4", name: "Dominick Meriweather" },
          { src: "MWWPic5", name: "Francisco Trinaldo" },
          { src: "MWWPic6", name: "Jordan Mein" },
          { src: "MWWPic7", name: "Julio Spadaccini" },
          { src: "MWWPic8", name: "Michael Irizarry" },
          { src: "MWWPic9", name: "Rousimar Palhares" },
          { src: "MWWPic10", name: "Ruan Machado" },
          { src: "MWWPic11", name: "Tyron Woodley" },
          { src: "MWWPic12", name: "Adilet Nurmatov" },
          { src: "MWWPic13", name: "Danny Mitchell" },
          { src: "MWWPic14", name: "Joao Zeferino" },
          { src: "MWWPic15", name: "Jozef Wittner" },
          { src: "MWWPic16", name: "Tony Ferguson" },
          { src: "MWWPic17", name: "efrain escudero" },
          { src: "MWWPic18", name: "gleison tibau" },
          { src: "MWWPic19", name: "sasha palatnikov" },
          { src: "MWWPic20", name: "yosdenis cedeno" },
          { src: "MWWPic21", name: "thiago lima" },
          { src: "MWWPic22", name: "pat pytlik" },
          { src: "MWWPic23", name: "marcos lloreda" },
          { src: "MWWPic24", name: "manuel mena" },
          { src: "MWWPic25", name: "lucas clay" },
          // { src: "MWWPic26", name: "lopez mota" },
          { src: "MWWPic27", name: "jose lucas alves" },
          { src: "MWWPic28", name: "handesson ferreira" },
          { src: "MWWPic29", name: "gabriel souza galindo" },
          { src: "MWWPic30", name: "eric alequin" },
          { src: "MWWPic31", name: "ej brooks" },
          { src: "MWWPic32", name: "carlos petruzzella" },
          { src: "MWWPic33", name: "ashley reece" },
          { src: "MWWPic34", name: "aziz pakhrudinov" },
          { src: "MWWPic35", name: "chris brown" },
          { src: "MWWPic36", name: "eduardo souza" },
          { src: "MWWPic37", name: "emin sharifow" },
          { src: "MWWPic38", name: "fabricio azevedo" },
          // { src: "MWWPic39", name: "ibrahima mane" },
          // { src: "MWWPic40", name: "jake ellenberger" },
          { src: "MWWPic41", name: "louis glismann" },
          { src: "MWWPic42", name: "magomed kabardiev" },
          { src: "MWWPic43", name: "ndidi alonu" },
          // { src: "MWWPic44", name: "stephen costello" },
          { src: "MWWPic45", name: "tahir abdullaev" },
          { src: "MWWPic46", name: "danny roberts" },
          // { src: "MWWPic47", name: "dillon danis" },
          { src: "MWWPic48", name: "neiman gracie" },
          { src: "MWWPic49", name: "dilano taylor" },
        ],
      },
      lightWeight: {
        label: "Lightweight",
        value: 5,
        fighters: [
          { src: "MLWPic1", name: "Amirkhon Alikhuzhaev" },
          { src: "MLWPic2", name: "Anthony Pettis" },
          { src: "MLWPic3", name: "Ayton De Paepe" },
          { src: "MLWPic4", name: "Charles Rosa" },
          { src: "MLWPic5", name: "Christos Giagos" },
          { src: "MLWPic6", name: "Ayinda Octave" },
          { src: "MLWPic7", name: "Jefferson Pontes" },
          { src: "MLWPic8", name: "Jeremy Stephens" },
          { src: "MLWPic9", name: "John Makdessi" },
          { src: "MLWPic10", name: "Kevin Lee" },
          { src: "MLWPic11", name: "Killys Mota" },
          { src: "MLWPic12", name: "Lucas Martins" },
          { src: "MLWPic13", name: "Mohamed Tarek Mohey" },
          { src: "MLWPic14", name: "Oscar Ownsworth" },
          { src: "MLWPic15", name: "Raimundo Batista" },
          { src: "MLWPic16", name: "Sidney Outlaw" },
          { src: "MLWPic17", name: "Stephen Beaumont" },
          { src: "MLWPic18", name: "Will Brooks" },
          { src: "MLWPic19", name: "Willian Lima" },
          { src: "MLWPic20", name: "Yan Cabral" },
          { src: "MLWPic21", name: "Benson Henderson" },
          { src: "MLWPic22", name: "Aaron Mckenzie" },
          { src: "MLWPic23", name: "Alex Oliveira" },
          { src: "MLWPic24", name: "Feruz Usmonov" },
          { src: "MLWPic25", name: "mukhiddin mamazhonov" },
          { src: "MLWPic26", name: "agshin babaev" },
          { src: "MLWPic27", name: "milos janicic" },
          { src: "MLWPic28", name: "Rodrigo vargas" },
          // { src: "MLWPic29", name: "adam masaev" },
          // { src: "MLWPic30", name: "aleksandr vertko" },
          { src: "MLWPic31", name: "andrew guisa" },
          { src: "MLWPic32", name: "gustavo ribeiro" },
          { src: "MLWPic33", name: "hongang yao" },
          { src: "MLWPic34", name: "ignacio capella" },
          { src: "MLWPic35", name: "jairo pacheco" },
          { src: "MLWPic36", name: "juliano prescendo" },
          { src: "MLWPic37", name: "kyle prepolec" },
          { src: "MLWPic38", name: "magomed akaev" },
          { src: "MLWPic39", name: "magomed yunusilau" },
          { src: "MLWPic40", name: "nathan ghareeb" },
          { src: "MLWPic41", name: "nicolas barna" },
          { src: "MLWPic42", name: "saad awad" },
          { src: "MLWPic43", name: "thibault gouti" },
          { src: "MLWPic44", name: "tim wilde" },
          { src: "MLWPic45", name: "tom oconnor" },
          { src: "MLWPic45", name: "tom oconnor" },
          { src: "MLWPic46", name: "damir ismagulov" },
          // { src: "MLWPic47", name: "jan quaeyhaegens" },
          { src: "MLWPic48", name: "john mitchell" },
          // { src: "MLWPic49", name: "krzyszlof mendlewski" },
          // { src: "MLWPic50", name: "mandel nallo" },
          { src: "MLWPic51", name: "mohamed tarek mohey" },
          { src: "MLWPic52", name: "murad umachiev" },
          { src: "MLWPic53", name: "romario orozco" },
          { src: "MLWPic54", name: "tofiq musayev" },
          { src: "MLWPic55", name: "vladislav rudnev" },
          { src: "MLWPic56", name: "saul rogers" },
          { src: "MLWPic57", name: "sage northcutt" },
          { src: "MLWPic58", name: "patricky pitbull" },
          
        ],
      },
      featherWeight: {
        label: "Featherweight",
        value: 6,
        fighters: [
          { src: "MFWPic1", name: "Alexsandro Cangaty" },
          { src: "MFWPic2", name: "Andre Harrison" },
          { src: "MFWPic3", name: "Claudeci Brito" },
          { src: "MFWPic4", name: "Deberson Batista" },
          { src: "MFWPic5", name: "Lance Palmer" },
          { src: "MFWPic6", name: "Marcel Adur" },
          { src: "MFWPic7", name: "Marcelo Dias" },
          // { "src": "MFWPic8", "name": "Marlon Moraes" },
          { src: "MFWPic9", name: "Patrizio de Sousa" },
          { src: "MFWPic10", name: "Renan Oliveira" },
          { src: "MFWPic11", name: "Mohammad Abzakh" },
          { src: "MFWPic12", name: "chad mendes" },
          { src: "MFWPic13", name: "chris wade" },
          { src: "MFWPic14", name: "huseyn aliyev" },
          { src: "MFWPic15", name: "mike grundy" },
          { src: "MFWPic16", name: "artem belakh" },
          { src: "MFWPic17", name: "edilson teixeira" },
          { src: "MFWPic18", name: "emrah sonmez" },
          { src: "MFWPic19", name: "jamie siraj" },
          { src: "MFWPic20", name: "julio arce" },
          { src: "MFWPic21", name: "leonardo diniz" },
          { src: "MFWPic22", name: "lucas miletich" },
          { src: "MFWPic23", name: "maike linhares" },
          { src: "MFWPic24", name: "marco beltran" },
          { src: "MFWPic25", name: "matthew wagy" },
          { src: "MFWPic26", name: "pedro colman" },
          { src: "MFWPic27", name: "rakhim midaev" },
          // { "src": "MFWPic28", "name": "tariq ismail" },
          { src: "MFWPic29", name: "tyler diamond" },
          { src: "MFWPic30", name: "zygimantas ramaska" },
          { src: "MFWPic31", name: "carlos alexandre" },
          { src: "MFWPic32", name: "diego davella" },
          { src: "MFWPic33", name: "donny matos" },
          { src: "MFWPic34", name: "kenny mokhonoana" },
          { src: "MFWPic35", name: "leonardo morales" },
          { src: "MFWPic36", name: "lucas caio" },
          { src: "MFWPic37", name: "thomas assis" },
          { src: "MFWPic38", name: "walter cagliandro" },
          { src: "MFWPic39", name: "ylies djiroun" },
          { src: "MFWPic40", name: "pedro carvalho" },
          { src: "MFWPic41", name: "brett johns" },
          { src: "MFWPic42", name: "kai kamaka" },
          { src: "MFWPic43", name: "khumoyun tukhtamuradov" },
          { src: "MFWPic44", name: "walter cogliandro" },
        ],
      },
      bantamWeight: {
        label: "Bantamweight",
        value: 7,
        fighters: [
          { src: "MBWPic1", name: "Denis Palancica" },
          { src: "MBWPic2", name: "Diego Teixeira" },
          { src: "MBWPic3", name: "Marcus Paulo Amaral" },
          { src: "MBWPic4", name: "Jimmie Rivera" },
          { src: "MBWPic5", name: "Marciano Ferreira" },
          { src: "MBWPic6", name: "Omar Arteaga" },
          { src: "MBWPic7", name: "Renan Barao" },
          { src: "MBWPic8", name: "Cameron Else" },
          { src: "MBWPic9", name: "brian kelleher" },
          { src: "MBWPic10", name: "timur valiev" },
          { src: "MBWPic11", name: "andre soukhamthath" },
          { src: "MBWPic12", name: "matheus nicolau" },
          { src: "MBWPic13", name: "ali wasuk" },
          { src: "MBWPic14", name: "andre barquero" },
          { src: "MBWPic15", name: "dileno lopes" },
          { src: "MBWPic16", name: "felipe zacaron" },
          { src: "MBWPic17", name: "josh hill" },
          { src: "MBWPic18", name: "lasha abramishvili" },
          { src: "MBWPic19", name: "marcelo guarilha" },
          { src: "MBWPic20", name: "raphael assuncao" },
          { src: "MBWPic21", name: "ray borg" },
          { src: "MBWPic22", name: "saidyokub kakhramonov" },
          { src: "MBWPic23", name: "xian ji" },
          { src: "MBWPic24", name: "altamiro de jesus" },
          { src: "MBWPic25", name: "edson lourenco" },
          { src: "MBWPic26", name: "farbod iran nezad" },
          { src: "MBWPic27", name: "gamlet aslanov" },
          // { src: "MBWPic28", name: "paulo portillo" },
          { src: "MBWPic29", name: "marlon moraes" },
          { src: "MBWPic30", name: "eric shelton" },
          { src: "MBWPic31", name: "victor madrigal" },
          { src: "MBWPic32", name: "ali guliev" },
          { src: "MBWPic33", name: "darrion caldwell" },
          { src: "MBWPic34", name: "jose maria tome" },
          { src: "MBWPic35", name: "louie sanoudakis" },
          { src: "MBWPic36", name: "mariano pipi" },
          { src: "MBWPic37", name: "miguel angel sosa" },
          { src: "MBWPic38", name: "ricky bandejas" },
          // { src: "MBWPic39", name: "razhabali shaydullaev" },
          { src: "MBWPic40", name: "zviad lazishvili" },
          
        ],
      },
    },
  },
};

Object.values(menContent.men.divisions).forEach((division) => {
  if (division.fighters && division.fighters.length > 0) {
    const seenNames = new Set();
    division.fighters = division.fighters.filter((fighter) => {
      if (fighter.name && !seenNames.has(fighter.name.toLowerCase())) {
        seenNames.add(fighter.name.toLowerCase());
        return true;
      }
      return false;
    });
    division.fighters.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });
  }
});

Object.values(womenContent.women.divisions).forEach((division) => {
  if (division.fighters && division.fighters.length > 0) {
    const seenNames = new Set();
    division.fighters = division.fighters.filter((fighter) => {
      if (fighter.name && !seenNames.has(fighter.name.toLowerCase())) {
        seenNames.add(fighter.name.toLowerCase());
        return true;
      }
      return false;
    });
    division.fighters.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });
  }
});

export { imageMapping, womenContent, menContent };
