import React, { useEffect, useState } from "react";
import ContactForm from "../contact-form/contactForm";
import Button from "react-bootstrap/Button";
import Footer from "../footer/footer";
import { ThemeContextProvider } from "../../themecontext";
import { Helmet } from "react-helmet-async";
import "./fighters.scss";
import { imageMapping, womenContent, menContent} from "../../utils/imageMapping";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const Fighters = () => {
  const [showMen, setShowMen] = useState(true);
  const [showWomen, setShowWomen] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const [tabValueWomen, setTabValueWomen] = useState("1");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeWomen = (event, newValue) => {
    setTabValueWomen(newValue);
  };

  return (
    <div className="fighters">
      <Helmet>
        <title>
          GFL MMA League | Team-Based MMA and Athlete Revenue Sharing
        </title>
        <meta
          name="description"
          content="The GFL MMA League is revolutionizing mixed martial arts with a team-based format, fair revenue sharing for athletes, and the biggest comeback stories of 2025."
        />
        <meta
          name="keywords"
          content="MMA league 2025,GFL MMA format,MMA fight teams 2025,athlete revenue sharing MMA,GFL MMA athletes 2025,top MMA fights 2025,team-based MMA,new MMA promotions 2025,GFL MMA comeback athletes"
        />
      </Helmet>
      <section className="title-scetion pt-0 pt-md-5">
        <div className="container">
          <h1 className="text-center pb-3">
            The Future of Combat Sports: Meet the Draft-Eligible Athletes!
          </h1>
          <div className="text-center text1">
          Explore the elite athletes poised to dominate the league. Divisions across men and women, ready to make history!
          </div>
          {/* <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="d-flex justify-content-around tags flex-wrap">
                    <a className="league-tags" href="#league-fighter" onClick={(e) => closeOffcanvas(e, "league-fighter")}>Fighter Benefits</a>
                    <a className="league-tags" href="#league-fan" onClick={(e) => closeOffcanvas(e, "league-fan")}>Fan Benefits</a>
                    <a className="league-tags" href="#league-partner" onClick={(e) => closeOffcanvas(e, "league-partner")}>Partner Benefits</a>
                    <a className="league-tags" href="#league-season" onClick={(e) => closeOffcanvas(e, "league-season")}>Season</a>
                    <a className="league-tags" href="#league-points" onClick={(e) => closeOffcanvas(e, "league-points")}>Points</a>
                  </div>
                </div>
              </div> */}
          {/* <div className="dashed-line my-5"></div> */}
          {/* <div className="text1">Discover the innovative model of the Global Fight League and the unique benefits it offers to fighters, fans, and partners</div> */}
        </div>
      </section>
      <div className="container pt-5">
        <div className="row pb-3">
          <div className="col-md">
            <h2 className="primary-color pb-2">Athletes</h2>
          </div>
          <div className="col-md text-md-end">
            <Button
              onClick={() => {
                setShowMen(true);
                setShowWomen(false);
              }}
              className={`button ${showMen ? "active" : ""}`}
            >
              Men's Divisions
            </Button>
            <Button
              onClick={() => {
                setShowMen(false);
                setShowWomen(true);
              }}
              className={`button ${showWomen ? "active" : ""}`}
            >
              Women's Divisions
            </Button>
          </div>
        </div>
        <div>
          {showMen && (

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    // scrollButtons
                    allowScrollButtonsMobile
                  >
                    {Object.entries(menContent.men.divisions).map(([key, div]) => (
                      <Tab label={div.label} value={String(div.value)} key={key} />
                    ))}
                    {/* <Tab label="Heavyweight" value="1" />
                    <Tab label="Light Heavyweight" value="2" />
                    <Tab label="Middleweight" value="3" />
                    <Tab label="Welterweight" value="4" />
                    <Tab label="Lightweight" value="5" />
                    <Tab label="Featherweight" value="6" />
                    <Tab label="Bantamweight" value="7" /> */}
                  </TabList>
                </Box>
                {Object.entries(menContent.men.divisions).map(([key, div]) => (
      <TabPanel value={String(div.value)} key={key}>
        <div className="row g-md-5">
          {div.fighters.map((fighter, index) => (
            <div className="col-md-4 fighrer-card" key={index}>
              <img src={imageMapping[fighter.src]}className="mw-100" alt={`Fighter ${index + 1}`} />
            </div>
          ))}
        </div>
      </TabPanel>
    ))}
                {/* <TabPanel value="1">
                  <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic11} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic12} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic13} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MHWPic14} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel> */}
                {/* <TabPanel value="2">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLHWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MMWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="4">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MWWPic11} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="5">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic11} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic12} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic13} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic14} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic15} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic16} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic17} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic18} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic19} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MLWPic20} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="6">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic7} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic8} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic9} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MFWPic10} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="7">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic5} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={MBWPic6} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel> */}
              </TabContext>
            </Box>
          )}
        </div>
        <div>
          {showWomen && (

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={tabValueWomen}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeWomen}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    // scrollButtons
                    allowScrollButtonsMobile
                  >
                    {Object.entries(womenContent.women.divisions).map(([key, div]) => (
                      <Tab label={div.label} value={String(div.value)} key={key} />
                    ))}
                    {/* <Tab label="Bantamweight" value="1" />
                    <Tab label="Flyweight" value="2" />
                    <Tab label="Strawweight" value="3" /> */}
                  </TabList>
                </Box>
                {Object.entries(womenContent.women.divisions).map(([key, div]) => (
      <TabPanel value={String(div.value)} key={key}>
        <div className="row g-md-5">
          {div.fighters.map((fighter, index) => (
            <div className="col-md-4 fighrer-card" key={index}>
              <img src={imageMapping[fighter.src]}className="mw-100" alt={`Fighter ${index + 1}`} />
            </div>
          ))}
        </div>
      </TabPanel>
    ))}
                {/* <TabPanel value="1">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={WBWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WBWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WBWPic3} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WBWPic4} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="2">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={WFWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WFWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="3">
                <div className="row g-md-5">
                    <div className="col-md-4 fighrer-card">
                      <img src={WSWPic1} className="mw-100" alt="Fighter"></img>
                    </div>
                    <div className="col-md-4 fighrer-card">
                      <img src={WSWPic2} className="mw-100" alt="Fighter"></img>
                    </div>
                  </div>
                </TabPanel> */}
              </TabContext>
            </Box>
          )}
        </div>

      </div>

      <div className="pt-3"></div>
      <ThemeContextProvider>
        <ContactForm />
      </ThemeContextProvider>
      <Footer />
    </div>
  );
};
export default Fighters;
