const ATHLETES_DATA = [
    {
        "name": "John 'The Crusher' Doe",
        "nickname": "The Crusher",
        "team": "Team Alpha",
        "weight": "175 lbs – Welterweight",
        "record": "20-3-1",
        "reach": "72 inches",
        "height": "6'1\"",
        "background": "John Doe started his wrestling career at the age of 15 with a background in Greco-Roman wrestling.",
        "image_links": [
            "https://example.com/john_doe1.jpg",
            "https://example.com/john_doe2.jpg"
        ]
    },
    {
        "name": "Jake 'Thunderbolt' Smith",
        "nickname": "Thunderbolt",
        "team": "Warrior Wrestling Club",
        "weight": "155 lbs – Lightweight",
        "record": "18-5-0",
        "reach": "68 inches",
        "height": "5'10\"",
        "background": "Former college wrestling champion, known for lightning-fast takedowns and sharp defensive moves.",
        "image_links": [
            "https://example.com/jake_smith1.jpg",
            "https://example.com/jake_smith2.jpg"
        ]
    },
    {
        "name": "Mike 'The Beast' Thompson",
        "nickname": "The Beast",
        "team": "Beast Mode Gym",
        "weight": "205 lbs – Light Heavyweight",
        "record": "25-2-0",
        "reach": "76 inches",
        "height": "6'3\"",
        "background": "Mike Thompson has a background in Brazilian Jiu-Jitsu, known for brute strength and technical submission skills.",
        "image_links": [
            "https://example.com/mike_thompson1.jpg",
            "https://example.com/mike_thompson2.jpg"
        ]
    },
    {
        "name": "Lucas 'The Eagle' Watson",
        "nickname": "The Eagle",
        "team": "Eagle Fight Club",
        "weight": "145 lbs – Featherweight",
        "record": "22-4-0",
        "reach": "70 inches",
        "height": "5'9\"",
        "background": "Lucas Watson is known for his grappling techniques and has a strong wrestling foundation from a young age.",
        "image_links": [
            "https://example.com/lucas_watson1.jpg",
            "https://example.com/lucas_watson2.jpg"
        ]
    },
    {
        "name": "Sam 'The Hurricane' Adams",
        "nickname": "The Hurricane",
        "team": "Storm Wrestling Academy",
        "weight": "185 lbs – Middleweight",
        "record": "19-6-0",
        "reach": "74 inches",
        "height": "6'0\"",
        "background": "Sam Adams brings a combination of wrestling and kickboxing skills, earning the nickname 'Hurricane' for his aggressive fighting style.",
        "image_links": [
            "https://example.com/sam_adams1.jpg",
            "https://example.com/sam_adams2.jpg"
        ]
    },
    {
        "name": "Chris 'Iron Fist' Walker",
        "nickname": "Iron Fist",
        "team": "Iron Fist Dojo",
        "weight": "170 lbs – Welterweight",
        "record": "17-7-1",
        "reach": "71 inches",
        "height": "5'11\"",
        "background": "Chris Walker is a well-rounded athlete with a focus on striking, utilizing his 'iron fist' to finish opponents.",
        "image_links": [
            "https://example.com/chris_walker1.jpg",
            "https://example.com/chris_walker2.jpg"
        ]
    },
    {
        "name": "Leo 'The Lion' Garcia",
        "nickname": "The Lion",
        "team": "Savage Gym",
        "weight": "155 lbs – Lightweight",
        "record": "23-2-0",
        "reach": "69 inches",
        "height": "5'9\"",
        "background": "Leo Garcia, known as 'The Lion,' has a ferocious fighting style, combining wrestling with powerful strikes.",
        "image_links": [
            "https://example.com/leo_garcia1.jpg",
            "https://example.com/leo_garcia2.jpg"
        ]
    },
    {
        "name": "Ethan 'The Phantom' Brown",
        "nickname": "The Phantom",
        "team": "Shadow Athletes",
        "weight": "145 lbs – Featherweight",
        "record": "15-8-0",
        "reach": "67 inches",
        "height": "5'8\"",
        "background": "Ethan Brown specializes in evading attacks and countering with precision, earning the nickname 'Phantom.'",
        "image_links": [
            "https://example.com/ethan_brown1.jpg",
            "https://example.com/ethan_brown2.jpg"
        ]
    },
    {
        "name": "Nate 'The Hammer' Martinez",
        "nickname": "The Hammer",
        "team": "Steel Warriors",
        "weight": "195 lbs – Cruiserweight",
        "record": "21-4-1",
        "reach": "75 inches",
        "height": "6'2\"",
        "background": "Nate Martinez is known for his heavy-handed strikes and grappling expertise, often finishing fights with powerful ground-and-pound.",
        "image_links": [
            "https://example.com/nate_martinez1.jpg",
            "https://example.com/nate_martinez2.jpg"
        ]
    },
    {
        "name": "Ryan 'The Cobra' Davis",
        "nickname": "The Cobra",
        "team": "StrikeForce Gym",
        "weight": "170 lbs – Welterweight",
        "record": "18-6-0",
        "reach": "73 inches",
        "height": "6'0\"",
        "background": "Ryan Davis is a master of submission wrestling, known for his quick reflexes and ability to strike with precision like a cobra.",
        "image_links": [
            "https://example.com/ryan_davis1.jpg",
            "https://example.com/ryan_davis2.jpg"
        ]
    },
    {
        "name": "Jack 'The Boulder' Thompson",
        "nickname": "The Boulder",
        "team": "Mountain Warriors",
        "weight": "220 lbs – Heavyweight",
        "record": "24-3-0",
        "reach": "77 inches",
        "height": "6'4\"",
        "background": "Jack Thompson is a powerhouse known for his overwhelming strength and resilience in the ring.",
        "image_links": [
            "https://example.com/jack_thompson1.jpg",
            "https://example.com/jack_thompson2.jpg"
        ]
    },
    {
        "name": "Matt 'Lightning' Lee",
        "nickname": "Lightning",
        "team": "Electric Gym",
        "weight": "145 lbs – Featherweight",
        "record": "20-5-1",
        "reach": "69 inches",
        "height": "5'9\"",
        "background": "Matt Lee is known for his speed and agility, striking like lightning with precision and speed.",
        "image_links": [
            "https://example.com/matt_lee1.jpg",
            "https://example.com/matt_lee2.jpg"
        ]
    },
    {
        "name": "Tony 'The Tiger' Sanchez",
        "nickname": "The Tiger",
        "team": "Savage Strikers",
        "weight": "165 lbs – Lightweight",
        "record": "22-3-0",
        "reach": "71 inches",
        "height": "5'10\"",
        "background": "Tony Sanchez is known for his aggressive style and relentless pressure in the ring, similar to a tiger.",
        "image_links": [
            "https://example.com/tony_sanchez1.jpg",
            "https://example.com/tony_sanchez2.jpg"
        ]
    },
    {
        "name": "Derek 'The Shadow' Roberts",
        "nickname": "The Shadow",
        "team": "Shadow Warriors",
        "weight": "180 lbs – Middleweight",
        "record": "18-7-1",
        "reach": "72 inches",
        "height": "5'11\"",
        "background": "Derek Roberts is known for his elusive movement and counter-striking, earning him the nickname 'Shadow.'",
        "image_links": [
            "https://example.com/derek_roberts1.jpg",
            "https://example.com/derek_roberts2.jpg"
        ]
    },
    {
        "name": "Alex 'The Hurricane' Martinez",
        "nickname": "The Hurricane",
        "team": "Storm Fight Club",
        "weight": "160 lbs – Lightweight",
        "record": "19-6-0",
        "reach": "70 inches",
        "height": "5'10\"",
        "background": "Alex Martinez combines wrestling and striking to overwhelm his opponents, earning the nickname 'Hurricane.'",
        "image_links": [
            "https://example.com/derek_roberts1.jpg",
            "https://example.com/derek_roberts2.jpg"
        ]
    },
]

export default ATHLETES_DATA