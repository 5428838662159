import React, { useCallback, useEffect, useState } from 'react'
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "react-bootstrap/Button";
import axios from 'axios'
import { apiEndPoint, headers } from "../../utils/constants";
import { ThemeContextProvider } from "../../themecontext";
import Footer from "../footer/footer";
import { Helmet } from 'react-helmet-async'
import SnackbarUI from '../common/snackbar';
import { MuiTelInput } from "mui-tel-input";


const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
};


const ContactUs = () => {
    const [formData, setFormData] = useState(initialState);
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [errors, setErrors] = useState({});
    // const [open, setOpenSnack] = useState(false);
    const [content, setContent] = useState('');
    // const [severity,setSeverity] = useState('success')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // const handleForm = useCallback((event) => {
    //     const { name, value, type, checked } = event.target;
    //     setFormData((prev) => ({
    //         ...prev,
    //         [name]: type === "checkbox" ? checked : value,
    //     }));
    //     setErrors((prev) => ({ ...prev, [name]: "" }));
    // }, []);

    const handleForm = useCallback((event) => {
        if (event.target) {
          const { name, value, type, checked } = event.target;
          setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
          }));
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else if (event) {
          setFormData((prev) => ({
            ...prev,
            phoneNumber: event
          }))
          setErrors((prev) => ({ ...prev, phoneNumber: "" }));
        }
      }, []);

    const resetForm = () => {
        setFormData({
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
        });
        setErrors({});
        setFormSubmitted(false);
    };

    const validateFormData = (data) => {
        let errors = {};
        if (data.name == "") {
            errors.name = "Required";
        }
        if (data.email == "") {
            errors.email = "Required";
        }
        if (!data.message) {
            errors.message = "Required";
        }

        if (data.phoneNumber == "") {
            errors.phoneNumber = "Required"
        }
        return errors
    };


    const saveDraftForm = (e) => {
        e.preventDefault();
        const validateErrors = validateFormData(formData);
        setErrors(validateErrors)
        if (Object.keys(validateErrors).length === 0) {
            axios.post(`${apiEndPoint}/contact-us-request`, formData, headers)
                .then((response) => {
                    if (response) {
                        setFormSubmitted(true)
                        // setOpenSnack(true)
                    }
                })
                .catch((e) => {
                    // setOpenSnack(true)
                    setContent("Something Went wrong !")
                    // setSeverity("error")
                })
        }
    };

    return (
        <div>
            <Helmet>
                <title>GFL MMA League | 2025 Athlete Comebacks & MMA's New Era</title>
                <meta property="og:title" content="GFL MMA League | 2025 Athlete Comebacks & MMA's New Era" />
                <meta name="description" content="Discover the GFL MMA League, launching in 2025 with top athletes returning to fight. Stay tuned for exclusive athlete updates, event announcements, and streaming details." />
                <meta name="keywords" content="MMA league 2025,GFL MMA league,MMA comeback 2025,mixed martial arts 2025,GFL fight schedule,MMA streaming platform,MMA promotions 2025,MMA fights 2025,top MMA athletes 2025" />
            </Helmet>
            <ThemeContextProvider>
                <div className="draft-application-form contactus pb-5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <h1>Contact Us</h1>
                                <div className="row">
                                    <div className="col-md-10">
                                        <p>
                                            We’d love to hear from you! Whether you have questions, feedback, or need assistance, please reach out. Our team is here to help and will get back to you as soon as possible.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="contact-box p-4">
                                    {!formSubmitted ? <form>
                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                    }}
                                                    name="name"
                                                    placeholder="Name*"
                                                    value={formData.name}
                                                    onChange={handleForm}
                                                />
                                            </FormControl>

                                            {errors && errors.name && (
                                                <p className="error mb-0 text-start">{errors.name}</p>
                                            )}
                                        </div>
                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                    }}
                                                    name="email"
                                                    placeholder="Email*"
                                                    value={formData.email}
                                                    onChange={handleForm}
                                                />
                                            </FormControl>
                                            {errors && errors.email && (
                                                <p className="error mb-0 text-start">{errors.email}</p>
                                            )}
                                        </div>
                                        {/* <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    aria-describedby="outlined-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                        inputMode: "numeric",
                                                        pattern: "[0-9]*",
                                                    }}
                                                    name="phoneNumber"
                                                    placeholder="Phone Number*"
                                                    value={formData.phoneNumber}
                                                    onChange={handleForm}
                                                />
                                            </FormControl>
                                            {errors && errors.phoneNumber && (
                                                <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                                            )}
                                        </div> */}
                                        <div className="pb-3">
                                            <FormControl className="w-100" variant="outlined">
                                                <MuiTelInput
                                                    defaultCountry="US"
                                                    value={formData.phoneNumber}
                                                    onChange={handleForm}
                                                    placeholder="Phone Number*"
                                                    name="phoneNumber"
                                                    error={!!errors.phoneNumber} // Show error outline if there's an error
                                                />
                                            </FormControl>
                                            {errors && errors.phoneNumber && (
                                                <p className="error mb-0 text-start">{errors.phoneNumber}</p>
                                            )}
                                        </div>
                                        <div className="pb-3">
                                            <TextField
                                                id="outlined-multiline-static"
                                                multiline
                                                rows={4}
                                                defaultValue="message"
                                                className="w-100"
                                                name="message"
                                                placeholder='Message'
                                                value={formData.message}
                                                onChange={handleForm}
                                            />
                                            {errors && errors.message && (
                                                <p className="error mb-0 text-start">{errors.message}</p>
                                            )}
                                        </div>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="w-100 rounded-pill"
                                            onClick={saveDraftForm}
                                        >
                                            Submit
                                        </Button>
                                        <div className="text-center error2">{content.length!=0 && content}</div>
                                    </form> : (
                                        <div className="thank-you-message text-center">
                                            <h3 className="text-white">Thanks for joining the GFL community. Stay tuned for updates. </h3>
                                            {/* <p className="text-white">We appreciate your interest and will be in touch soon.</p> */}
                                            <Button
                                                variant="primary"
                                                className="mt-3 px-5"
                                                onClick={resetForm}
                                            >
                                                Ok
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </ThemeContextProvider>
            {/* <SnackbarUI open={open} setOpenSnack={setOpenSnack} content={content} severity={severity} /> */}
        </div>
    )
}

export default ContactUs