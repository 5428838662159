import React, { useEffect, useState } from "react";
import Footer from "../footer/footer";
import { ThemeContextProvider } from "../../themecontext";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import managersCoaches from "../../assets/gfl-press-release-cities-team-managers-coaches-16jan2025.pdf";
import gflDraft from "../../assets/gfl-press-release-virtual-draft-24jan2025.pdf";

import "./media.scss";

const NewsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [tabValue, setTabValue] = useState("1");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Helmet>
        <title>About GFL MMA League | Revolutionizing Mixed Martial Arts</title>
        <meta
          name="description"
          content="Learn about the GFL MMA League, our mission to transform mixed martial arts, and how we’re creating new opportunities for athletes and fans in 2025."
        />
        <meta
          name="keywords"
          content="about GFL MMA league,GFL MMA 2025,MMA athlete-focused league,MMA sports organization,MMA combat sports 2025,GFL mission MMA,GFL athlete comeback stories,GFL MMA vision,MMA revenue sharing model,team-based MMA league"
        />
      </Helmet>
      <div className="media">
        <div className="container">
          <h1 className="text-center pb-4">News</h1>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  // variant="scrollable"
                  centered
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Articles" value="1" />
                  <Tab label="Press Releases" value="2" />
                </TabList>
              </Box>
              <TabPanel value="2">
                <div className="pt-4">
                  <div className="pr-card pb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pr-img">
                          <img
                            src={require("../../assets/draft-press-release.png")}
                            className="mw-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 py-4">
                        <h3>
                          GLOBAL FIGHT LEAGUE UNVEILS DETAILS FOR FRIDAY’S
                          VIRTUAL DRAFT
                        </h3>
                        <div className="pb-2 date">January 24, 2025</div>
                        <p>
                          The Global Fight League (GFL), the new team-based MMA
                          organization launching in April 2025, today announced
                          additional details for the first-ever GFL Virtual
                          Draft, which will take place this Friday, January
                          24th, at 8 PM EST. Fans worldwide catch tune in to
                          watch the selection LIVE on MMA GFL’s official
                          platforms: Instagram, Facebook, YouTube and Twitch,
                          plus the show will also air on the social channels of
                          DAZN and PPV.com, the Triller TV app, and Rumble.
                        </p>
                        <a
                          href={gflDraft}
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pr-card pb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pr-img">
                          <img
                            src={require("../../assets/announces6cities.jpg")}
                            className="mw-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 py-4">
                        <h3>
                          Global Fight League Unveils Six Inaugural Cities,
                          Marquee Team Managers and Coaches, and More MMA
                          Legends enter the GFL 2025 Draft
                        </h3>
                        <div className="pb-2 date">January 16, 2025</div>
                        <p>
                          The Global Fight League (GFL) is proud to announce the
                          six cities that will serve as the foundation for its
                          inaugural...
                        </p>
                        <a
                          href={managersCoaches}
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="pr-card pb-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pr-img">
                          <img
                            src={require("../../assets/women-athletes.jpg")}
                            className="mw-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 py-4">
                        <h3>
                          Global Fight League Unveils List of 60 Signed Women
                          Athletes Ahead of Jan. 24 Draft, First Season
                        </h3>
                        <div className="pb-2 date">Jan 3, 2025</div>
                        <p>
                          Women from 16 Countries - Including World Champions -
                          to Share Revenue Pool with Male Teammates for the
                          First Time in MMA History
                        </p>
                        <a
                          href="https://www.prlog.org/13055090-global-fight-league-unveils-list-of-60-signed-women-athletes-ahead-of-jan-24-draft-first-season.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="pr-card">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pr-img">
                          <img
                            src={require("../../assets/PR-MMA-April-2025.png")}
                            className="mw-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 py-4">
                        <h3>
                          Global Fight League Announces Initial Funding and Sets
                          Launch Plan for New Team-Based MMA Property
                        </h3>
                        <div className="pb-2 date">
                          Dec 11, 2024 at 4:00 PM EST
                        </div>
                        <p>
                          The Global Fight League, a new team-based MMA
                          property, today announced that it has successfully
                          closed a multi-million-dollar seed round and is set to
                          launch its first season in April 2025.{" "}
                        </p>
                        <a
                          href="https://www.prweb.com/releases/global-fight-league-announces-initial-funding-and-sets-launch-plan-for-new-team-based-mma-property-302328655.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel value="1">
                <div className="pt-4">
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/ringsideintel.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          10 UFC And MMA Greats You May Not Realize Will Soon Be
                          Fighting For New GFL Promotion In 2025
                        </h3>
                        <div className="pb-2 date">January 17, 2025</div>
                        <p>
                          The Global Fight League (GFL) is hoping to provide an
                          alternative to the UFC. The promotion with a
                          team-based format will kick things off in 2025 with an
                          upcoming draft from their pool of fighters.
                        </p>
                        <a
                          href="https://ringsideintel.com/mma/lists/top-10-ufc-and-mma-great-fighting-in-gfl/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/usa-today-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          GFL announces new draft entrees, managers, coaches,
                          and team cities
                        </h3>
                        <div className="pb-2 date">
                          January 16, 2025 12:40 PM ET
                        </div>
                        <p>
                          The fledgling Global Fight League continued to get its
                          business in order Thursday with several important
                          announcements.
                        </p>
                        <a
                          href="https://mmajunkie.usatoday.com/2025/01/global-fight-league-team-cities-managers-coaches"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/forbes-logo.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          New MMA Promotion Increases Weight-Class Limits For
                          Divisions
                        </h3>
                        <div className="pb-2 date">
                          Jan 10, 2025,03:28 AM EST
                        </div>
                        <p>
                          It seems every day, a new and interesting detail about
                          the Global Fight League (GFL) is revealed. On
                          Thursday, the league’s official X account shared its
                          weight classes with higher weight limits across the
                          board.
                        </p>
                        <a
                          href="https://www.forbes.com/sites/brianmazique/2025/01/10/new-mma-promotion-increases-weight-class-limits-for-divisions/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/usa-today-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Kevin Lee interested to rematch Tony Ferguson in
                          fledgling GFL
                        </h3>
                        <div className="pb-2 date">
                          January 10, 2025 12:10 PM ET
                        </div>
                        <p>
                          With GFL his new home, Kevin Lee hopes he can finally
                          run things back with a former rival.
                        </p>
                        <a
                          href="https://mmajunkie.usatoday.com/2025/01/kevin-lee-rematch-tony-ferguson-gfl"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/mmamania.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Longtime UFC vet Tony Ferguson ‘in talks’ with GFL
                          ahead of promotion’s April 4 debut
                        </h3>
                        <div className="pb-2 date">
                          Jan 7, 2025, 4:01 PM EST
                        </div>
                        <p>
                          Former Ultimate Fighting Championship (UFC) interim
                          lightweight champion, Tony Ferguson, recently told his
                          social media followers that fight news was “coming
                          soon,” but it sounds like “El Cucuy” was not referring
                          to a potential Octagon comeback.
                        </p>
                        <a
                          href="https://www.mmamania.com/2025/1/7/24338479/longtime-ufc-vet-tony-ferguson-in-talks-gfl-ahead-promotions-april-4-debut-mma"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/CTV.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Windsor fighter Randi Field signs to newly established
                          MMA league
                        </h3>
                        <div className="pb-2 date">Jan 5, 2025</div>
                        <p>
                          After a fight in March 2023, Windsor MMA fighter Randi
                          Field feared a long list of injuries would derail her
                          career.
                        </p>
                        <a
                          href="https://windsor.ctvnews.ca/windsor-fighter-randi-field-signs-to-newly-established-mma-league-1.7165080"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/usa-today-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League's list of male fighter signings is
                          long and distinguished
                        </h3>
                        <div className="pb-2 date">
                          Jan 5, 2025 at 9:15 AM ET
                        </div>
                        <p>
                          The upstart Global Fight League recently announced a
                          slew of female signings. And just like the women, the
                          men signed to the new promotion is a who’s-who of
                          former standouts.
                        </p>
                        <a
                          href="https://mmajunkie.usatoday.com/lists/global-fight-league-gfl-list-male-fighter-signings-ufc-mma-bellator-pfl"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/lowkick-mma-new.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Ex-UFC Standout Hannah Goldy Signs with Global Fight
                          League
                        </h3>
                        <div className="pb-2 date">Jan 4, 2025</div>
                        <p>
                          Former UFC fighter Hannah Goldy has officially signed
                          with the Global Fight League, marking a new chapter in
                          her mixed martial arts career. The announcement comes
                          as GFL prepares to launch its innovative team-based
                          league in April 2025.
                        </p>
                        <a
                          href="https://www.lowkickmma.com/ex-ufc-hannah-goldy-signs-global-fight-league/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/mmasucka.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Former Bellator Champ Can Thrive in GFL If Given
                          Chance
                        </h3>
                        <div className="pb-2 date">Jan 4, 2025</div>
                        <p>
                          It’s been a while since we’ve seen Julia Budd suit up
                          in action for an MMA fight. To find the last time that
                          the former Bellator MMA Women’s Featherweight Champion
                          set foot inside of a cage, you’d have to travel back
                          in time to the night of June 16, 2023.
                        </p>
                        <a
                          href="https://mmasucka.com/bellator-champ-julia-budd-looking-to-turn-page-gfl-25/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/mmasucka.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Crystal Ball Projections For 2025: Part Two of Two
                        </h3>
                        <div className="pb-2 date">Jan 3, 2025</div>
                        <p>
                          Ready or not, 2025 is now in full swing. Now that
                          we’re about three days into this journey that we call
                          a new year, there are still a few more projections
                          from the fabled crystal ball that have to be given
                          out.
                        </p>
                        <a
                          href="https://mmasucka.com/crystal-ball-mma-2025-what-may-come-to-fruition/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/mmamania.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Former UFC heavyweight champion Cain Velasquez signs
                          with GFL
                        </h3>
                        <div className="pb-2 date">
                          Jan 3, 2025 at 11:58 AM EST
                        </div>
                        <p>
                          Global Fight League (GFL), the new team-based MMA
                          organization launching in April 2025, recently signed
                          former Ultimate Fighting Championship (UFC)
                          heavyweight champion, Cain Velasquez. The 42 year-old
                          Californian will serve as Team Manager alongside
                          longtime American Kickboxing Academy (AKA) coach,
                          Javier Mendez.
                        </p>
                        <a
                          href="https://www.mmamania.com/2025/1/3/24335001/former-ufc-heavyweight-champion-cain-velasquez-signs-with-gfl-mma"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/usa-today-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League's updated roster of female
                          signings includes Julia Budd, Hannah Goldy, Randi
                          Field and more
                        </h3>
                        <div className="pb-2 date">
                          Jan 3, 2025 at 5:35 PM ET
                        </div>
                        <p>
                          The new Global Fight League will hold its inaugural
                          draft later this month, and dozens of female fighters
                          will be on the list.
                        </p>
                        <a
                          href="https://mmajunkie.usatoday.com/lists/global-fight-league-female-women-roster-signings-hannah-goldy-randi-field-paige-vanzant"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/forbes-logo.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Paige VanZant Is Returning To MMA, Takes ‘Perfect
                          Opportunity’
                        </h3>
                        <div className="pb-2 date">
                          Jan 3, 2025 at 01:03 AM EST
                        </div>
                        <p>
                          Paige VanZant has participated in a variety of combat
                          sports, but the 30-year-old has decided to return to
                          MMA.
                        </p>
                        <a
                          href="https://www.forbes.com/sites/brianmazique/2025/01/03/paige-vanzant-is-returning-to-mma-takes-perfect-opportunity/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/mmanytt-logo.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Tyron Woodley on GFL: “FIGHTING IN SUPERMATCHES”
                        </h3>
                        <div className="pb-2 date">Dec 19, 2024</div>
                        <p>
                          Tyron Woodley has confirmed his place in Global Fight
                          League (GFL), a new MMA organization set to launch
                          early next year.
                        </p>
                        <a
                          href="https://www.mmanytt.com/latest-news/tyron-woodley-on-gfl-fighting-in-supermatches/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/msn-logo.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League: Everything To Know About The
                          UFC's Newest Competitor
                        </h3>
                        <div className="pb-2 date">Dec 15, 2024</div>
                        <p>
                          MMA fans have another organization to look forward to
                          as the Global Fight League (GFL) emerges as a fresh
                          competitor to the UFC.
                        </p>
                        <a
                          href="https://www.msn.com/en-us/sports/mma_ufc/global-fight-league-everything-to-know-about-the-ufcs-newest-competitor/ar-AA1vUQAJ?apiversion=v2&noservercache=1&domshim=1&renderwebcomponents=1&wcseo=1&batchservertelemetry=1&noservertelemetry=1"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/tpg-logo.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>Global Fight League Aims To Rival UFC Dominance</h3>
                        <div className="pb-2 date">Dec 15, 2024</div>
                        <p>
                          Innovative new promotion set to launch with diverse
                          roster and bold vision for MMA's future.
                        </p>
                        <a
                          href="https://evrimagaci.org/tpg/global-fight-league-aims-to-rival-ufc-dominance-90614?srsltid=AfmBOop-u0D3vTIVnRgIPSdaSrSlJ9x7ktwyuNlfbkcE6NKni5puWRJt"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/thesportster.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>Global Fight League Aims To Rival UFC Dominance</h3>
                        <div className="pb-2 date">Dec 15, 2024</div>
                        <p>
                          MMA fans have another organization to look forward to
                          as the Global Fight League (GFL) emerges as a fresh
                          competitor to the UFC.
                        </p>
                        <a
                          href="https://www.thesportster.com/global-fight-league-everything-to-know-about-the-ufcs-newest-competitor/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/sports-illustrated-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          UFC-Killer? New League Signs Former Champs, Stacked
                          Roster With Dozens of Stars
                        </h3>
                        <div className="pb-2 date">Dec 13, 2024</div>
                        <p>
                          Everything you need to know about the Global Fight
                          League (GFL).
                        </p>
                        <a
                          href="https://www.si.com/fannation/mma/news/ufc-killer-new-league-signs-former-champs-stacked-roster-with-dozens-of-stars"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/yahoo-sports-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League reveals massive list of signings,
                          including 8 ex-UFC champs, for new team-based MMA
                          organization
                        </h3>
                        <div className="pb-2 date">
                          Dec 12, 2024 at 4:08 PM EST
                        </div>
                        <p>
                          The Global Fight League (GFL) is ready to take the MMA
                          world by storm. GFL founder Darren Owen revealed
                          Wednesday on Uncrowned's "The Ariel Helwani Show" that
                          the promotion's first events will occur in April 2025.
                        </p>
                        <a
                          href="https://uk.sports.yahoo.com/news/global-fight-league-reveals-massive-list-of-signings-including-8-ex-ufc-champs-for-new-team-based-mma-organization-201248109.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/newsweek-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Multiple Former UFC Stars Added to New Global Fight
                          League Organization
                        </h3>
                        <div className="pb-2 date">
                          Dec 11, 2024 at 3:01 PM EST
                        </div>
                        <p>
                          The MMA space is set to grow even more, as a new
                          organization has been announced. That new organization
                          is called the "Global Fight League," and it is headed
                          by Darren Owen.
                        </p>
                        <a
                          href="https://www.newsweek.com/sports/multiple-former-ufc-stars-added-new-global-fight-league-organization-1999424"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/forbes-logo.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          10 Former UFC Champions Headline New MMA
                          Organization’s Roster
                        </h3>
                        <div className="pb-2 date">
                          Dec 11, 2024 at 06:45 PM EST
                        </div>
                        <p>
                          The new team-based MMA organization was unveiled on
                          The Ariel Helwani Show on Wednesday, December 11, and
                          it was boosted by an interesting financial model that
                          promises a 50-50 revenue split with athletes, and a
                          roster packed with names MMA fans will immediately
                          recognize.
                        </p>
                        <a
                          href="https://www.forbes.com/sites/brianmazique/2024/12/11/10-former-ufc-champions-headline-new-mma-organizations-roster/"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/yahoo-sports-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League announces launch, roster includes
                          giant list of former UFC athletes
                        </h3>
                        <div className="pb-2 date">
                          Dec 11, 2024 at 5:45 PM EST
                        </div>
                        <p>
                          A new player has entered the MMA landscape, and it has
                          arrived with some big names.
                        </p>
                        <a
                          href="https://sports.yahoo.com/global-fight-league-announces-launch-224513993.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/usa-today-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League announces launch, roster includes
                          giant list of former UFC athletes
                        </h3>
                        <div className="pb-2 date">
                          Dec 11, 2024 at 5:45 PM EST
                        </div>
                        <p>
                          A new player has entered the MMA landscape, and it has
                          arrived with some big names. Wednesday, Global Fight
                          League (GFL) announced its official launch will come
                          in the opening weeks of 2025.
                        </p>
                        <a
                          href="https://mmajunkie.usatoday.com/2024/12/global-fight-league-launch-ufc-woodley-silva-werdum"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img
                            src={require("../../assets/yahoo-sports-logo.png")}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Anthony Pettis, Kevin Lee explain details of new GFL
                          deals: 'I couldn't say no to this kind of contract'
                        </h3>
                        <div className="pb-2 date">
                          Dec 11, 2024 at 6:45 PM EST
                        </div>
                        <p>
                          MMA's newest organization Global Fight League (GFL) is
                          officially set to debut in 2025.
                        </p>
                        <a
                          href="https://sports.yahoo.com/anthony-pettis-kevin-lee-explain-details-of-new-gfl-deals-i-couldnt-say-no-to-this-kind-of-contract-235530319.html"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="news-card p-4 mb-4">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="news-img d-flex justify-content-center align-items-center">
                          <img src={require("../../assets/sherdog-logo.png")} />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h3>
                          Global Fight League Reveals Team-Based Format, Roster
                          with Multiple Ex-UFC Champs
                        </h3>
                        <div className="pb-2 date">Dec 11, 2024 </div>
                        <p>
                          On Wednesday, promotion founder Darren Owen announced
                          plans to launch a team-based MMA league with a season
                          format beginning in April 2025. According to a
                          release, the league will feature six teams in “major
                          global markets.”
                        </p>
                        <a
                          href="https://www.sherdog.com/news/news/Global-Fight-League-Reveals-TeamBased-Format-Roster-with-Multiple-ExUFC-Champs-195692"
                          target="_blank"
                          className="read-more"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default NewsPage;
