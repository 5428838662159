import React, { useState } from "react";
import "./home.scss";

const HoverVideoDisplay = ({ imageSrc, videoSrc }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
        <div
        className="city position-relative d-flex justify-content-center align-items-center hover-container"
        onMouseEnter={() => setIsHovered(true)} // Desktop
        onMouseLeave={() => setIsHovered(false)} // Desktop
        onTouchStart={() => setIsHovered(true)} // Mobile 
        onTouchEnd={() => setIsHovered(false)} // Mobile 
        // style={{
        //   backgroundImage: `url(${require("../../assets/los-angeles-assassins.jpg")})`,
        // }}
      >
        {/* Display Image */}
        {!isHovered && <img src={imageSrc} alt="Hover to see video" />}
        
        {/* Display Video */}
        {isHovered && (
          <video autoPlay controls preload="auto" poster={imageSrc}>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    );
  };

  export default HoverVideoDisplay;